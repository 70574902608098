import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/StDriver11.css";
import BarTwo from "./Bar-two";
import { Text } from "./../components/Translate";

const initialState = {
  Arkikl: "",
  montelo: "",
  marka: "",
  xroma: "",
  etos: "",
};

const StDriver11 = () => {
  const [licenseplate, setlicenseplate] = useState("");
  const [manufacturer, setmanufacturer] = useState("");
  const [model, setmodel] = useState("");
  const [year, setyear] = useState("");
  const [color, setColor] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [{ Arkikl, montelo, marka, xroma, etos }, setState] =
    useState(initialState);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    setState({
      Arkikl: localStorage.getItem("pinakida_A_oximatos") ?? "",
      montelo: localStorage.getItem("manufacturer_A_oximatos") ?? "",
      marka: localStorage.getItem("marka_A_oximatos") ?? "",
      xroma: localStorage.getItem("color_A_oximatos") ?? "",
      etos: localStorage.getItem("year_A_oximatos") ?? "",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("pinakida_A_oximatos", Arkikl);
    localStorage.setItem("manufacturer_A_oximatos", montelo);
    localStorage.setItem("marka_A_oximatos", marka);
    localStorage.setItem("color_A_oximatos", xroma);
    localStorage.setItem("year_A_oximatos", etos);
    onButtonContainerClick();
  };

  const onButtonContainerClick = useCallback(() => {
    navigate("/1st-driver7");
  }, [navigate]);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/vehicles`, { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setlicenseplate(data[0].licensePlate);
        setmodel(data[0].model);
        setColor(data[0].color);
        setyear(data[0].year);
        setmanufacturer(data[0].manufacturer);
        localStorage.setItem("marka_A_oximatos", data[0].model);
        localStorage.setItem("color_A_oximatos", data[0].color);
        localStorage.setItem("pinakida_A_oximatos", data[0].licensePlate);
        localStorage.setItem("year_A_oximatos", data[0].year);
        localStorage.setItem("manufacturer_A_oximatos", data[0].manufacturer);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
      });
  });

  return (
    <div className="st-driver11">
      <div className="wrapperpage">
        <div id="wrapper">
          <BarTwo headerName={<Text>vehicleadetails</Text>} />
          <div className="progress-bar-parent27">
            <div className="progress-bar31">
              <div className="progress-bar-child135" />
              <div className="progress-bar-child136" />
            </div>
            {isFetching ? (
              <></>
            ) : licenseplate != null && licenseplate != "" ? (
              <>
                <div className="div183">
                  <Text>choosevehicle</Text>
                </div>
                <div className="frame-wrapper9">
                  <div className="selection1-group">
                    <div className="selection11">
                      <div className="frame-parent88">
                        <div className="iconcar-empty-container">
                          <div class="radio-item2">
                            <input
                              type="radio"
                              checked
                              name="radio"
                              id="radio1"
                            />
                            <label
                              for="radio1"
                              style={{ backgroundColor: "white" }}
                              className="div184"
                            >
                              <img
                                className="icontheft"
                                alt=""
                                src="/iconcar-empty.svg"
                              />
                              <div style={{ marginLeft: "50px" }}>
                                <Text>licenseplate</Text>: {licenseplate}
                              </div>
                            </label>
                            <div className="selection1-item" />
                            <div className="frame-parent89">
                              <div className="peugeot-frame">
                                <div className="peugeot2">
                                  <Text>brand</Text>: {manufacturer}
                                </div>
                              </div>
                              <div className="wrapper55">
                                <div className="div185">
                                  <Text>model</Text>: {model}
                                </div>
                              </div>
                            </div>
                            <div className="selection1-inner1">
                              <div className="parent29">
                                <div className="div186">
                                  <Text>colour</Text>: {color}
                                </div>
                                <div className="div187">
                                  <Text>year</Text>: {year}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-wrapper38">
                  <div className="button73" onClick={onButtonContainerClick}>
                    <div className="continue73">
                      <Text>next</Text>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <form name="nextpage" validate onSubmit={handleSubmit}>
                <div className="parent79">
                  <div className="div355">
                    <Text>fillinvehicleadetails</Text>
                  </div>
                  <div className="frame-parent160">
                    <div className="frame-parent161">
                      <div className="input-wrapper179">
                        <div className="input376">
                          <div className="input-title203">
                            <Text>licenseplate</Text>
                          </div>
                          <input
                            type="text"
                            className="input-wrapper180"
                            id="Arkikl"
                            value={Arkikl}
                            name="Arkikl"
                            required
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="input376">
                        <div className="input-title203">
                          <Text>brand</Text>
                        </div>
                        <input
                          type="text"
                          className="input-wrapper180"
                          id="marka"
                          value={marka}
                          name="marka"
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input376">
                        <div className="input-title203">
                          <Text>year</Text>
                        </div>
                        <input
                          type="text"
                          className="input-wrapper180"
                          id="etos"
                          value={etos}
                          name="etos"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="frame-parent161">
                      <div className="input376">
                        <div className="input-title203">
                          <Text>model</Text>
                        </div>
                        <input
                          type="text"
                          className="input-wrapper180"
                          id="montelo"
                          value={montelo}
                          name="montelo"
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input376">
                        <div className="input-title203">
                          <Text>colour</Text>
                        </div>
                        <input
                          type="text"
                          className="input-wrapper180"
                          id="xroma"
                          value={xroma}
                          name="xroma"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-wrapper38">
                  <button
                    type="submit"
                    className="button73"
                    style={{ width: "288px", color: "white" }}
                  >
                    <Text>next</Text>
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StDriver11;
