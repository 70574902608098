import { useCallback,useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Accident10.css";
import BarTwo from "./Bar-two";
import Uploader from "../components/Upload";
import { Text } from "./../components/Translate";

const Accident10 = () => {
  const navigate = useNavigate();
  const [anafora, setAstunomia] = useState('Οχι');
  const onYesContainerClick = useCallback(() => {
    navigate("/accident9");
  }, [navigate]);

  function NextPage3(e) {
    if (anafora !== ''){
        localStorage.setItem("anafora_astunomias", anafora );
        onYesContainerClick()
      //  console.log(anafora)
    }
  }

  return (
      <div className="accident10">
          <div className="wrapperpage">
              <div id="wrapper">
                  <BarTwo headerName={<Text>accidentdescription</Text>} />
                  <div className="progress-bar-parent8">
                      <div className="progress-bar10">
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child62" />
                      </div>
                      <div className="title146">
                          <div className="div55"><Text>policereportif</Text></div>
                      </div>
                      <div className="yesno8"> 
                      <div className="icontheft-parent" onClick={() => {setAstunomia("Ναι") }}>
                                    <div class="radio-item">
                                        <input type="radio" name="radio" id="radio1" checked={anafora === 'Ναι'}/>
                                        <label for="radio1" style={{textAlign:'left'}}><Text>yes</Text></label>
                                        <br/>
                                        <Uploader id='uploader' name='photo_astunomias'/>

                                    </div>
                                    
                                   
                            </div>
                       
                            <div className="icontheft-parent" onClick={() => {setAstunomia("Οχι") }}>
                                    <div class="radio-item">

                                        <input type="radio" name="radio" id="radio2" checked={anafora === 'Οχι'}/>
                                        <label for="radio2" style={{textAlign:'left'}}><Text>no</Text></label>
                                    </div>
                            </div>
                         
                      </div>
                  </div>
                  <div className="button-wrapper38">
                      <div className="button73" onClick={NextPage3}>
                          <div className="continue23"><Text>next</Text></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>


  );
};

export default Accident10;
