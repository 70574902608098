import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Accident1 from "./pages/Accident1";
import SignIn from "./components/SignIn";
import Accident5 from "./pages/Accident5";
import Accident10 from "./pages/Accident10";
import Accident16 from "./pages/Accident16";
import Accident17 from "./pages/Accident17";
import Accident18 from "./pages/Accident18";
import StDriver7 from "./pages/StDriver7";
import StDriver9 from "./pages/StDriver9";
import StDriver11 from "./pages/StDriver11";
import Summary from "./pages/Summary";
import Saved from "./pages/Saved";
import Sent from "./pages/Sent";
import Shared from "./pages/Shared";
import Share from "./pages/Share";
import Witness2 from "./pages/Witness2";
import StatementIntro from "./pages/StatementIntro";
import StatementIntro1 from "./pages/StatementIntro1";
import StatementIntro2 from "./pages/StatementIntro2";
import StatementIntro4 from "./pages/StatementIntro4";
import NdDriver7 from "./pages/NdDriver7";
import NdDriver8 from "./pages/NdDriver8";
import NdDriver9 from "./pages/NdDriver9";
import NdDriver10 from "./pages/NdDriver10";
import Injured3 from "./pages/Injured3";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Illustration from './components/Illustration'
import Illustration2 from "./components/Illustration2";
import Illustration1 from "./components/Illustration11";

import PrivateRoute from "./components/PrivateRoute"



import { TranslationButtons } from "./components/Translate";
import NdDriver5 from "./pages/NdDriver5";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("refresh") != undefined && localStorage.getItem("refresh") !== "") {
      console.log(localStorage.getItem("refresh"));
      const d = new Date();
      let now = d.toISOString();

      console.log(now, "now");
      const timestamp = Date.now();
      const date = new Date(localStorage.getItem("access-timer"));
      const timestamp2 = date.getTime();
      console.log(timestamp);
      console.log(timestamp2);

      if (timestamp2 - timestamp < 100000) {
        const token = localStorage.getItem("refresh");
        console.log(localStorage.getItem("refresh"));
        const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/token`;
        fetch(url, {
          method: "POST",
          body: JSON.stringify({ token: `${token}` }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            localStorage.setItem("access", data.access.token);
            localStorage.setItem("access-timer", data.refresh.expiresAt);
            localStorage.setItem("refresh", data.refresh.token);
            console.log(localStorage);
          })
          .catch((error) => {
            console.log("log out");
            navigate("/");
            localStorage.setItem("refresh", "");
          });
      }
    }
  });

  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/accident12" element={<Accident16 />} />
        <Route path="/accident14" element={<Accident17 />} />
        <Route path="/accident1" element={<Accident18 />} />
        <Route path="/accident19" element={<Accident1 />} />
        <Route path="/accident16" element={<Accident10 />} />
        <Route path="/accident9" element={<Accident5 />} />
        <Route path="/1st-driver7" element={<StDriver7 />} />
        {/* <Route path="/1st-driver10" element={<StDriver9 />} /> */}
        <Route path="/1st-driver" element={<StDriver11 />} />
        <Route path="/summary" element={<PrivateRoute Component={Summary} />} />

  
        <Route path="/saved" element={<Saved />} />
        <Route path="/sent" element={<Sent />} />
        <Route path="/shared" element={<Shared />} />
        <Route path="/share" element={<Share />} />
        <Route path="/witness" element={<Witness2 />} />
        <Route path="/statement-intro" element={<StatementIntro />} />
        <Route path="/statement-intro1" element={<StatementIntro1 />} />
        <Route path="/statement-intro2" element={<StatementIntro2 />} />
        <Route path="/statement-intro4" element={<StatementIntro4 />} />
        <Route path="/2nd-driver9" element={<NdDriver7 />} />
        <Route path="/2nd-driver5" element={<NdDriver8 />} />
        <Route path="/2nd-driver6" element={<NdDriver5 />} />
        <Route path="/2nd-driver11" element={<NdDriver9 />} />
        <Route path="/2nd-driver" element={<NdDriver10 />} />
        <Route path="/injured" element={<Injured3 />} />
        <Route path="/info" element={<Illustration />} />
      <Route path="/info3" element={<Illustration2 />} />
      <Route path="/info2" element={<Illustration1 />} />
      </Routes>
      {/* <TranslationButtons /> */}
    </>
  );
}
export default App;
