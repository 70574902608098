import { useEffect, useState} from "react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ Component }) => {
 
const [isAuthenticated, setIsAuthenticated] = useState(true);
    const navigate = useNavigate();

 // Your authentication logic goes here...
 useEffect(() => {
   
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('access')}` },
    };
      
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims/`, requestOptions)
     .then((response) =>{
      return response.json();  
    }).then((data) =>{ 
     if (data[data.length-1].status ==='COMPLETE'){
        setIsAuthenticated(false)
        navigate('/home')
     }
      
     })
    .catch(error => {
     console.log('problem')})
   
  })
 
  return isAuthenticated ? <Component /> : <Navigate to="/home" />;
};
export default PrivateRoute;