import { useCallback,useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Accident17.css";
import BarTwo from "./Bar-two";
import Checkbox from '@mui/material/Checkbox';
import { Text } from './../components/Translate';
import { lazy } from "react";

const Accident17 = () => {
  let boxes = 34;
  const navigate = useNavigate();
  const [checked,setChecked]=useState(false)
  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  });
 
  const handleChange = (e) => {
 
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;
      
    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked ) {
      setUserInfo({
        languages: [...languages, value],
        response: [...languages, value],
      });
   //  console.log(value,'ewewewe')
      localStorage.setItem(value, checked)
    }
  
    // Case 2  : The user unchecks the box
    else if(!checked){
      setUserInfo({
        languages: languages.filter((e) => e !== value),
        response: languages.filter((e) => e !== value),
      });
    //  console.log(value,'ewewewe')
      localStorage.setItem(value, checked)
    }
  };
  useEffect(() => {
  const inputName = JSON.parse(localStorage.getItem('test'))
  console.log(inputName)


  })

  const onButtonContainerClick = () => {
    navigate("/accident16");
    localStorage.setItem('test',JSON.stringify(userinfo.languages))
   // console.log(userinfo.response)
  };


  return (
      <div className="accident17">
          <div className="wrapperpage">
              <div id="wrapper">
                  <BarTwo headerName={<Text>accidentdescription</Text>} />

                  <div className="progress-bar-parent13">
                      <div className="progress-bar15">
                          <div className="progress-bar-child87" />
                          <div className="progress-bar-child87" />
                          <div className="progress-bar-child87" />
                          <div className="progress-bar-child87" />
                          <div className="progress-bar-child91" />
                          <div className="progress-bar-child91" />
                      </div>
                      <div className="question-parent">
                          <div className="question">
                              <div className="parent8">
                                  <div className="div74"><Text>accidentconditions</Text></div>
                                  <div className="div75"><Text>selectaccidentconditions</Text></div>
                              </div>
                          </div>
                          <div className="frame-parent58">
                              <div className="frame-parent59">
                                  <div className="a-wrapper">
                                      <b className="a"><Text>vehiclea</Text></b>
                                      <b style={{fontSize:'14px',margin:'4px 0px'}}><Text>vehicleayour</Text></b>
                                  </div>
                                  <div className="a-wrapper">
                                      <b className="a"><Text>vehicleb</Text></b>
                                      <b style={{fontSize:'14px',margin:'4px 0px'}}><Text>vehiclebyour</Text></b>
                                  </div>
                              </div>
                              <div className="list-item-parent">
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="Parked_A"
                    value="Parked_A"
                    defaultChecked={JSON.parse(localStorage.getItem('Parked_A'))}
                    id="id1"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>parked</Text></div>
                                      <div className="checkbox">
                                      <input
                                      
                    className="form-check-input"
                    type="checkbox"
                    name="Parked_B"
                    value="Parked_B"
                    defaultChecked={JSON.parse(localStorage.getItem('Parked_B'))}
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="starting_open_door_A"
                    value="starting_open_door_A"
                    defaultChecked={JSON.parse(localStorage.getItem('starting_open_door_A'))}
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>leavingparkingplace</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    value="starting_open_door_B"
                    defaultChecked={JSON.parse(localStorage.getItem('starting_open_door_B'))}
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    value="for_parking_A"
                    defaultChecked={JSON.parse(localStorage.getItem('for_parking_A'))}
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>enteringparkingplace</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    value="for_parking_B"
                    defaultChecked={JSON.parse(localStorage.getItem('for_parking_B'))}
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('exit_parking_A'))}
                    value="exit_parking_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>emergingfromparking</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('exit_parking_B'))}
                    value="exit_parking_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('enter_parking_A'))}
                    value="enter_parking_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>enteringparkingground</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('enter_parking_B'))}
                    value="enter_parking_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('enter_circular_path_A'))}
                    value="enter_circular_path_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>enteringroundabout</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('enter_circular_path_B'))}
                    value="enter_circular_path_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('move_circular_path_A'))}
                    value="move_circular_path_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>circulatingroundabout</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('move_circular_path_B'))}
                    value="move_circular_path_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('rear_collision_same_lane_A'))}
                    value="rear_collision_same_lane_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>strikingthereal</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('rear_collision_same_lane_B'))}
                    value="rear_collision_same_lane_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('moving_same_direction_different_lane_A'))}
                    value="moving_same_direction_different_lane_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>goingsamedirection</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('moving_same_direction_different_lane_B'))}
                    value="moving_same_direction_different_lane_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('lane_change_A'))}
                    value="lane_change_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>changinlanes</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('lane_change_B'))}
                    value="lane_change_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('overtaking_A'))}
                    value="overtaking_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>overtaging</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    value="overtaking_B"
                    defaultChecked={JSON.parse(localStorage.getItem('overtaking_B'))}
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('turn_right_A'))}
                    value="turn_right_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>turningtoright</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('turn_right_B'))}
                    value="turn_right_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('turn_left_A'))}
                    value="turn_left_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>turningtoleft</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('turn_left_B'))}
                    value="turn_left_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={JSON.parse(localStorage.getItem('reverse_A'))}
                    name="languages"
                    value="reverse_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>reversing</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={JSON.parse(localStorage.getItem('reverse_B'))}
                    name="languages"
                    value="reverse_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('opposite_traffic_direction_A'))}
                    value="opposite_traffic_direction_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>encroaching</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('opposite_traffic_direction_B'))}
                    value="opposite_traffic_direction_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('right_on_intersection_A'))}
                    value="right_on_intersection_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>comingfromright</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"                    
                    defaultChecked={JSON.parse(localStorage.getItem('right_on_intersection_B'))}
                    value="right_on_intersection_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                                  <div className="list-item">
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('priority_sign_violation_A'))}
                    value="priority_sign_violation_A"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                      <div className="div76"><Text>notobservingsigns</Text></div>
                                      <div className="checkbox">
                                      <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    defaultChecked={JSON.parse(localStorage.getItem('priority_sign_violation_A'))}
                    value="priority_sign_violation_B"
                    id="flexCheckDefault"
                    onChange={(e)=>handleChange(e)}
                  />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="button-wrapper38">
                      <div className="button73" onClick={onButtonContainerClick}>
                          <div className="continue21"><Text>next</Text></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  );
};

export default Accident17;
