import { useCallback,useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/StDriver7.css";
import BarTwo from "./Bar-two";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Text } from "./../components/Translate";
import { countBy } from "lodash";
import Modal from "../components/Modalvalidate";
import chevron from '../assets/insideicon.svg'



const initialState = {
    firstName:localStorage.getItem('firstname_A_driver'),
    lastName: localStorage.getItem('lastname_A_driver'),
    phone: localStorage.getItem('phone_A_odigou'),
    email: localStorage.getItem('email_A_odigou'),
    dateOfBirth: localStorage.getItem('im_gennisis_A_odigou'),
    address: localStorage.getItem('address_A_odigou'),
    postalCode: localStorage.getItem('tk_A_odigou'),
    country: localStorage.getItem('country_A_odigou'),
    ar_diplomatos: localStorage.getItem('ar_diplomatos_A_odigou'),
    category: localStorage.getItem('category_diplomatos_A_odigou'),
    expires_at: localStorage.getItem('isxus_diplomatos_A_odigou')
  }
const StDriver7 = () => {
    const [ModalValidate, setModalValidate] = useState(false);

    const ValidateModal = () => {
      setModalValidate(true)
      console.log(5);
    }
  
    const navigate = useNavigate();
    const [{ lastName, firstName, phone, email, country, dateOfBirth, address,postalCode,ar_diplomatos, category,expires_at}, setState] = useState(initialState)
    

    const onButtonContainerClick = useCallback(() => {
        navigate("/statement-intro4");
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }))
      }

    useEffect(() => {
      setState({
        firstName: localStorage.getItem("firstname_A_driver") ?? "",
        lastName: localStorage.getItem("lastname_A_driver") ?? "",
        phone: localStorage.getItem("phone_A_odigou") ?? "",
        email: localStorage.getItem("email_A_odigou") ?? "",
        dateOfBirth: localStorage.getItem("im_gennisis_A_odigou") ?? "",
        address: localStorage.getItem("address_A_odigou")?? "",
        postalCode: localStorage.getItem("tk_A_odigou")?? "",
        country: localStorage.getItem("country_A_odigou")?? "",
        ar_diplomatos: localStorage.getItem("ar_diplomatos_A_odigou")?? "",
        category: localStorage.getItem("category_diplomatos_A_odigou")?? "",
        expires_at: localStorage.getItem("isxus_diplomatos_A_odigou")?? "",
      });
    },[]);

      const handleSubmit = (e) => {
        e.preventDefault()
        if(document.getElementById("phoneA").value!='' && document.getElementById("firstNameA").value!='' && document.getElementById("lastNameA").value!=''){
        localStorage.setItem("Name_A_driver", (lastName +' '+ firstName) );
        localStorage.setItem("firstname_A_driver", firstName );
        localStorage.setItem("lastname_A_driver", lastName  );
        localStorage.setItem("phone_A_odigou", phone);
        localStorage.setItem("email_A_odigou", email );
        localStorage.setItem("country_A_odigou", country);
        localStorage.setItem("im_gennisis_A_odigou", dateOfBirth );
        localStorage.setItem("address_A_odigou", address);
        localStorage.setItem("tk_A_odigou", postalCode);
        localStorage.setItem("ar_diplomatos_A_odigou", ar_diplomatos );
        localStorage.setItem("isxus_diplomatos_A_odigou", expires_at );
        localStorage.setItem("category_diplomatos_A_odigou", category);
    
        console.log(lastName)
        onButtonContainerClick()
        }
        else{
            ValidateModal(true)
        }
      }

    return (
        <div className="st-driver7">
            <div className="wrapperpage">
                <div id="wrapper">
                    <BarTwo headerName={<Text>vehicleadetails</Text>} />
                    <div className="progress-bar-parent23">
                        <div className="progress-bar27">
                            <div className="progress-bar-child127" />
                            <div className="progress-bar-child127" />
                        </div>
                        <div className="div159">
                            <div className="parent24">
                                <div className="div160"><Text>myvehicledriverdetails</Text></div>
                                <div className="div161"><Text>fieldswithasterisk</Text></div>
                            </div>
                        </div>
                        <Accordion className="infopersonalexpand13">
                            <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>
                                    <div className="icon-parent20">
                                        <img className="icon38" alt="" src="/icon311.svg" />
                                        <div className="div343"><Text>personaldetails</Text>*</div>
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                <div className="frame-parent156">
                                    <div className="input-parent47">
                                        <div className="input342">
                                            <div className="input-title185"><Text>surname</Text>*</div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='lastNameA'
                                                value={lastName}
                                                name='lastName'
                                                required       
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title185"><Text>name</Text>*</div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='firstNameA'
                                                value={firstName}
                                                name='firstName'
                                                required       
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="input-parent47">
                                        <div className="input342">
                                            <div className="input-title185"><Text>phonenumber</Text>*</div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='phoneA'
                                                value={phone}
                                                name='phone'
                                                required       
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title185"><Text>email</Text></div>
                                            <input
                                                type="text"
                                              
                                                className="input-wrapper180"
                                                id='email'
                                                value={email}
                                                name='email'
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title185"><Text>dateofbirth</Text></div>
                                            <input
                                                type="date"
                                                className="input-wrapper180"
                                                id='dateOfBirth'
                                                value={dateOfBirth}
                                                name='dateOfBirth'      
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="input-parent47">
                                        <div className="input342">
                                            <div className="input-title190"><Text>address</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='address'
                                                value={address}
                                                name='address'      
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title190"><Text>postalcode</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='postalCode'
                                                value={postalCode}
                                                name='postalCode'      
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title190"><Text>country</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='country'
                                                value={country}
                                                name='country'      
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                </div>
                            </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="infopersonalexpand13">
                            <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>
                                    <div className="icon-parent20">
                                        <img className="icon38" alt="" src="/icon7.svg" />
                                        <div className="div343"><Text>driverlicensedetails</Text></div>
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className="infoadressexpand5">
                                        <div className="input-parent47">
                                            <div className="input342">
                                                <div className="input-title190"><Text>driverlicenseno</Text></div>
                                                <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='ar_diplomatos'
                                                value={ar_diplomatos}
                                                name='ar_diplomatos'      
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title190"><Text>category</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='category'
                                                value={category}
                                                name='category'      
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title185"><Text>validthrough</Text></div>
                                            <input
                                                type="date"
                                                className="input-wrapper180"
                                                id='expires_at'
                                                value={expires_at}
                                                name='expires_at'      
                                                onChange={handleChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                       
                       
                       <button type='submit' className="button73" style={{width:'288px',color:'white'}} onClick={handleSubmit} ><Text>next</Text></button>
                       <Modal state="αποθηκεύτηκε" show={ModalValidate} onHide={() => setModalValidate(false)}></Modal>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default StDriver7;
