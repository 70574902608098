import { useCallback,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./../components/Translate";
import info from '../assets/illustration-info.svg'
import "./Illustration.css";
import setBodyColor from "./bodycolor";
import Bar3 from "../pages/Bar-three";

const Illustration = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setBodyColor({color: "white"})
 
  })
  setBodyColor({color: "white"})
  const onIllustration1ContainerClick = useCallback(() => {
    navigate("/info2");
  }, [navigate]);

  return (
    <div className="wrapperpage" style={{backgroundColor:'white'}}>
    <div class="h-100 d-flex align-items-center justify-content-center">
    <div id="wrapper">
      <Bar3 headerName={<Text>howitworks</Text>}/>
    <div className="illustration1" onClick={onIllustration1ContainerClick}>

      <section className="illustration-info-wrapper">
        <img
          className="illustration-info-icon"
          loading="eager"
          alt=""
          src={info}
        />
      </section>
      <section className="collect-elements">
        <div className="div387"><Text>gatherdataofpeopleinvolved</Text></div>
        <div className="div388">
          <Text>savedataofpeopleinvolvedondevice</Text>
        </div>
      </section>
      <img className="notch-icon" alt="" src="/notch@2x.png" />
      <img
        className="recording-indicator-icon"
        alt=""
        src="/recording-indicator.svg"
      />
      <div className="ellipse-set-wrapper">
        <div className="ellipse-set">
          <div className="arrow-menu" />
          <div className="arrow-menu1" />
          <div className="arrow-menu2" />
        </div>
      </div>
    </div>
    </div>
      </div>
    </div>
  );
};

export default Illustration;
