import { useCallback, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "../css/Accident5.css";
import BarTwo from "./Bar-two";
import ModalInfo from "../components/ModalComplete";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Uploader from "../components/Upload";
import { AudioRecorder } from 'react-audio-voice-recorder';
import { Text } from "./../components/Translate";
import chevron from '../assets/insideicon.svg'



const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = false;
    console.log(url)
    localStorage.setItem("perigrafi_audio", url );
  };
  const addAudioElement2 = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = false;
    console.log(url)
    localStorage.setItem("perigrafi_fthorwn_audio", url );
  };



  const Accident5 = () => {
  const [perigrafi, setPerigrafi] = useState('');
  const [perigrafi_fthorwn, setPerigrafifthorwn] = useState('');
  const [ModalComplete, setModalComplete] = useState(false);

  const CompleteModal = () => {
    localStorage.setItem("perigrafi", perigrafi );
    localStorage.setItem("perigrafi_fthorwn", perigrafi_fthorwn );
   // console.log(perigrafi_fthorwn, perigrafi)
    setModalComplete(true)
  //  console.log(5);
  }

  useEffect(() => {
    setPerigrafi(localStorage.getItem("perigrafi") ?? "");
    setPerigrafifthorwn(localStorage.getItem("perigrafi_fthorwn") ?? "");
  }, []);


  const navigate = useNavigate();

  const onFrameContainer14Click = useCallback(() => {
    navigate("/statement-intro4");

  }, [navigate]);



  return (
        <div className="accident5">
            <div className="wrapperpage">
                <div id="wrapper">
                <BarTwo headerName={<Text>accidentdescription</Text>} />
                <div className="progress-bar-parent34">
                <div className="progress-bar10">
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child57" />
                          <div className="progress-bar-child57" />
                      </div>
                    <div className="div338">
                    <div className="text4">
                                <div className="text4">
                                    <div className="div32"><Text>accidentanddamagedescription</Text></div>
                                </div>
                            </div>
                    </div>
                    <Accordion className="infopersonalexpand13">
                        <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>
                                <div className="icon-parent20">
                                    <div className="div343"><Text>accidentphotos</Text></div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           
                                    
                                        <Uploader name='photo_atiximatos' />
        
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="infopersonalexpand13">
                        <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>
                                <div className="icon-parent20">
                                    <div className="div343"><Text>damagephotos</Text></div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Uploader name='photo_fthorwn'/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="infopersonalexpand13">
                        <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>
                                <div className="icon-parent20">
                                    <div className="div343"><Text>description</Text></div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* <p style={{float:'left'}}><Text>recordaccidentdescription</Text></p>
                            <AudioRecorder 
                            onRecordingComplete={addAudioElement}
                            audioTrackConstraints={{
                                noiseSuppression: true,
                                echoCancellation: true,
                            }} 
                            downloadOnSavePress={true}
                            downloadFileExtension="mp3"
                            /> */}
                            <div className="input376">
                                                <div className="input-title203" style={{alignSelf:'auto'}}><Text>typeaccidentdescription</Text></div>
                                                <textarea
                                                type="text"
                                                rows="4" 
                                                className="input-wrapper180"
                                                id='perigrafi'
                                                value={perigrafi}
                                                name='Arkikl'
                                                required       
                                                onChange={(e) => setPerigrafi(e.target.value)}></textarea>
                                            </div>
                                            <br/><br/>
                                            {/* <p style={{float:'left'}}><Text>recorddamagedescription</Text></p>
                            <AudioRecorder 
                            onRecordingComplete={addAudioElement2}
                            audioTrackConstraints={{
                                noiseSuppression: true,
                                echoCancellation: true,
                            }} 
                            downloadOnSavePress={true}
                            downloadFileExtension="mp3"
                            /> */}
                            <div className="input376">
                                                <div className="input-title203" style={{alignSelf:'auto'}}><Text>typedamagedescription</Text></div>
                                                <textarea
                                                type="text"
                                                rows="4" 
                                                className="input-wrapper180"
                                                id='perigrafi'
                                                value={perigrafi_fthorwn}
                                                name='Arkikl'
                                                required       
                                                onChange={(e)=>setPerigrafifthorwn(e.target.value)}/>
                                            </div>
                        </AccordionDetails>
                        
                    </Accordion>
                </div>
                <ModalInfo show={ModalComplete} onHide={() => setModalComplete(false)}></ModalInfo>
                <div className="button-wrapper38">
                    <div className="button73" >
                        <div className="continue73" onClick={CompleteModal}><Text>next</Text></div>
                    </div>
                </div>
                </div>
            </div>
        </div>


  );
};

export default Accident5;
