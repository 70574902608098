import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Accident16.css";
import BarTwo from "./Bar-two";
import DragandDrop from "../components/DragandDrop";
import { Text } from "./../components/Translate";

const Accident16 = () => {


  
  return (
      <div className="accident16">
          <div className="wrapperpage">
              <div id="wrapper">
                  <BarTwo headerName={<Text>accidentdescription</Text>} />
                  <div className="progress-bar-parent14">
                      <div className="progress-bar16">
                          <div className="progress-bar-child81" />
                          <div className="progress-bar-child81" />
                          <div className="progress-bar-child81" />
                          <div className="progress-bar-child84" />
                          <div className="progress-bar-child84" />
                          <div className="progress-bar-child84" />
                      </div>
                      <div className="frame-parent60">
                          <DragandDrop />
                      </div>
                  </div>

                 
              </div>
          </div>
      </div>

  );
};

export default Accident16;

