import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./../components/Translate";
import "../css/Sent.css";

const Sent = () => {
  const navigate = useNavigate();

  const onButtonContainerClick = useCallback(() => {


  }, [navigate]);

  const onButtonContainer1Click = useCallback(() => {
    navigate("/saved");
  }, [navigate]);

  const onShareContainerClick = useCallback(() => {
    navigate("/share");
  }, [navigate]);

  return (
    <div className="sent">
      <b className="b43"><Text>agreedstatement</Text></b>
      <img className="arrow-header-2-icon38" alt="" src="/arrow-header-2.svg" />
      <div className="menu-overlay38">
        <div className="frame41">
          <img className="group-icon136" alt="" src="/group.svg" />
          <img className="group-icon136" alt="" src="/group1.svg" />
          <img className="group-icon138" alt="" src="/group2.svg" />
        </div>
      </div>
      <div className="button-wrapper34">
        <div className="button59" onClick={onButtonContainerClick}>
          <div className="pdf-group">
            <div className="div198">Αρχική</div>
            <img className="frame-child75" alt="" src="/frame-1391.svg" />
          </div>
        </div>
      </div>
      <div className="iconyes-parent2">
        <img className="iconyes4" alt="" src="/iconyes3.svg" />
        <div className="parent39">
          <b className="b44">Η δήλωση στάλθηκε</b>
          <div className="button60" onClick={onButtonContainer1Click}>
            <div className="pdf-group">
              <div className="div198">Αποθήκευση pdf</div>
              <img className="frame-child76" alt="" src="/frame-2556.svg" />
            </div>
          </div>
          <div className="share2" onClick={onShareContainerClick}>
            <div className="button61">
              <div className="continue47">Επιβεβαίωση</div>
            </div>
            <div className="button62">
              <div className="pdf-group">
                <div className="div198">Share</div>
                <img className="frame-child76" alt="" src="/frame-2950.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sent;
