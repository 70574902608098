import { useCallback, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/StatementIntro2.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BarTwo from "./Bar-two";
import SetInsurance from "../components/insurance";
import { Text } from "./../components/Translate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const StatementIntro4 = () => {
    const navigate = useNavigate();
    const [Asfal, setAsfal] = useState('');
    const onFrameContainer2Click = useCallback(() => {
        navigate("/statement-intro4");
    }, [navigate]);
    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAsfal = (newAsfal) => {
    	setAsfal(newAsfal);
        
  	}

    
      const NewPage = (Asfalia) => {

       console.log(Asfal)

        if (Asfal !== ''){
            localStorage.setItem("asfalistiki_B_odigou", Asfal);
            onFrameContainer2Click()
            console.log(Asfal)
        }
        
  	}
      useEffect(() => {
      
      
       
      })
     
    
      

    return (
        <div className="statement-intro4">
            <div className="wrapperpage">
                <div id="wrapper">
                    <BarTwo headerName={<Text>newstatement</Text>} />
                    <div className="frame-parent117">
                        <div className="frame-wrapper15" onClick={handleShow}>
                            <div className="onboarding-selector-frame">
                                <div className="onboarding-selector2">
                               

                                    <img className="onboarding-selector-inner" alt="" src="/frame-26212.svg" />
                                    <div className="parent52">
                                        <div className="div238"><Text>agreedstatement</Text></div>
                                        <div className="div239"><Text>agreedstatementexplanation</Text></div>
                                    </div>
                                    <img className="insideicon34" alt="" src="/insideicon.svg" />
                                </div>
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose} size="lg" scrollable={true} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header>
                                <div className="content">
                                    <FontAwesomeIcon icon={faXmark} onClick={handleClose} style={{cursor:'pointer',float:'right',color:'#46bedc'}}/>
                                    <div className="title-subtitle" style={{marginTop:'20px'}}>
                                        
                                        <b className="title221"><Text>agreedstatement</Text></b>
                                    </div>
                                    <img className="content-child" alt="" src="/frame-26211.svg" style={{padding:'14px 0px'}}/>

                                    <div className="text8"><Text>agreedstatementprerequisite</Text></div>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <SetInsurance name="ΑΤΛΑΝΤΙΚΗ ΕΝΩΣΗ" radio="radio21"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="Allianz" radio="radio1" handleAsfal={handleAsfal}/>
                                <SetInsurance name="AIG EUROPE" radio="radio12"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ΓΕΝΙΚΗ ΠΑΝΕΛΛΑΔΙΚΗ" radio="radio27"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ΔΥΝΑΜΙΣ" radio="radio3" handleAsfal={handleAsfal} />
                                <SetInsurance name="CITY INSURANCE" radio="radio14"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ΕΥΡΩΠΑΪΚΗ ΠΙΣΤΗ" radio="radio5"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ΕΥΡΩΠΗ " radio="radio23"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="EUROLIFE " radio="radio24"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ERGO ΑΣΦΑΛΙΣΤΙΚΗ" radio="radio25"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="EURO INSURANCES" radio="radio11"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="EUROINS.AD INSURANCE" radio="radio16"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ΕΥΡΩΠΑΪΚΗ ΕΝΩΣΙΣ" radio="radio18"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="Η ΕΘΝΙΚΗ" radio="radio17"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="GENERALI HELLAS" radio="radio7"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="GROUPAMA ΦΟΙΝΙΞ" radio="radio20"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="Hellas Direct INSURANCE" radio="radio13"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="INTERAMERICAN" radio="radio2" handleAsfal={handleAsfal} />
                                <SetInsurance name="INTERASCO" radio="radio9"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="INTERLIFE" radio="radio6"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ΝP ΑΣΦΑΛΙΣΤΙΚΗ-ΝΕΟΣ ΠΟΣΕΙΔΩΝ" radio="radio8"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ΣΥΝΕΤΑΙΡΙΣΤΙΚΗ" radio="radio22"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ΟΡΙΖΩΝ" radio="radio19"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="ΥΔΡΟΓΕΙΟΣ" radio="radio4" handleAsfal={handleAsfal} />
                                <SetInsurance name="PERSONAL INSURANCE" radio="radio26"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="PRIME INSURANCE" radio="radio10"  handleAsfal={handleAsfal}/>
                                <SetInsurance name="QIC EUROPE" radio="radio15"  handleAsfal={handleAsfal}/>
   
                            </Modal.Body>
                            <Modal.Footer>{(Asfal==='' && localStorage.getItem("asfalistiki_B_odigou")!=='')?
                                <p></p>:<Button id='nextbutton'  className="button73" onClick={NewPage}>
                                <Text>next</Text>
                            </Button>}
                            </Modal.Footer>
                        </Modal>
                        <div className="frame-wrapper16">
                            <div className="onboarding-selector-frame">
                                <div className="onboarding-selector2">
                                    <img className="onboarding-selector-child1" alt="" src="/frame-26201.svg" />
                                    <div className="parent52">
                                        <div className="div238"><Text>accidentcompensation</Text></div>
                                        <div className="div239"><Text>accidentcompensationexplanation</Text></div>
                                    </div>
                                    <img className="insideicon34" alt="" src="/insideicon.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatementIntro4;
