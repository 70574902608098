import React, { useEffect, useState, useContext, useCallback } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

import en from "../assets/locales/en.json";
import el from "../assets/locales/el.json";

const availableLocales = [
  { key: "en", label: "EN" },
  { key: "el", label: "EL" },
];

const locales = {
  en: en,
  el: el,
};

const TranslateContext = React.createContext(null);

const TranslateProvider = ({ children, languages, defaultLanguage }) => {
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    // temporarily use english as the only language

    /* if (localStorage.getItem("locale") != null) {
      setLanguage(localStorage.getItem("locale"));
    } else {
      if (defaultLanguage != null) {
        handleSetLanguage(defaultLanguage);
      } else {
        // Add Localstorage saving and reading
        let userLang = navigator.language || navigator.userLanguage;
        const indexInLanguages = languages.findIndex(
          (item) => item.key == userLang.substring(0, 2)
        );
        if (indexInLanguages > -1) {
          setLanguage(languages[indexInLanguages].key);
        } else {
          setLanguage("en");
        }
      }
    } */

    setLanguage("en");
  }, []);

  const handleGetLanguage = useCallback(() => {
    return languages;
  }, [languages]);

  const handleSetLanguage = useCallback((value) => {
    document.documentElement.lang = value;
    setLanguage(value);
    localStorage.setItem("locale", value);
  }, []);

  return (
    language != null && (
      <TranslateContext.Provider
        value={{
          language: language,
          getLanguages: handleGetLanguage,
          setLanguage: handleSetLanguage,
        }}
      >
        {children}
      </TranslateContext.Provider>
    )
  );
};

const useTranslate = () => {
  let translateContext = useContext(TranslateContext);
  return translateContext;
};

const Translate = ({ value, hasToExist = false }) => {
  const { language } = useTranslate();
  const text = locales[language];
  if (hasToExist === true && text[value] == null) {
    return null;
  }
  return text[value] ?? value;
};

const TranslationButtons = () => {
  const usetranslate = useTranslate();

  const setLanguage = (value) => {
    usetranslate.setLanguage(value);
  };

  return (
    <ButtonGroup>
      {availableLocales.map((item) => (
        <Button
          onClick={() => setLanguage(item.key)}
          sx={{
            mt: 3,
            mb: 2,
            bgcolor:
              usetranslate.language === item.key ? "#46bedc" : "transparent",
            borderColor: "#46bedc",
            color: usetranslate.language === item.key ? "#FFFFFF" : "#46bedc",
          }}
          key={item.key}
          variant={
            usetranslate.language === item.key ? "contained" : "outlined"
          }
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const Text = ({
  // as: Component = 'p',
  children,
  optional = true,
  ...props
}) => {
  const text = Translate({ value: children, hasToExist: !optional });
  // return text != null && <Component {...props}>{text}</Component>;
  return text != null && <>{text}</>;
};

export {
  TranslateProvider,
  Translate,
  useTranslate,
  availableLocales,
  TranslationButtons,
  Text,
};
