import { useCallback, useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/StatementIntro4.css";
import Bar from "./Bar";
import BarTwo from "./Bar-two";
import { Text } from "./../components/Translate";
import okicon from "../assets/iconmileaccepted1.svg"

const StatementIntro2 = () => {
  const [button, setButton] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();

  const onListnewStatementContainerClick = useCallback(() => {
    navigate("/2nd-driver");
  }, [navigate]);

  const onClickAccidentDescription = useCallback(() => {
    navigate("/accident19");
  }, [navigate]);

  const onClickInjured = useCallback(() => {
    navigate("/injured");
  }, [navigate]);

  const onClickWhitness = useCallback(() => {
    navigate("/witness");
  }, [navigate]);

  const onClickFirst = useCallback(() => {
    navigate("/1st-driver");
  }, [navigate]);

  const onClickEpiskopisi = useCallback(() => {
    const datetime = localStorage.getItem('accident_datetime')
    if( datetime!== null){
    navigate("/summary");
    }
  }, [navigate]);

  const jsonData = localStorage

  const e = document.getElementById('json');

  function download(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  function onDownload(){
      const d = localStorage.getItem('travmaties')
      const myObj = JSON.parse(d);
      console.log(myObj)
  }

  useEffect(() => {
    if(localStorage.getItem('geolat') && localStorage.getItem('tilefono_B_odigou')){
        setButton(false)
    }
    if (localStorage.getItem('oxima_B_odigou') || localStorage.getItem('accident_datetime')) {
        const elem = document.getElementById("diagrafi");
        elem.style.color = '#d94242';
        setDisabled(false)
    }
    console.log('r')
  });

  const DeleteAll = (e) => {  // clear locale storage but keep Token values
    const access = localStorage.getItem('access')
    const refresh = localStorage.getItem('refresh')
    const expires = localStorage.getItem('access-timer')
    localStorage.clear()
    localStorage.setItem('access',access)
    localStorage.setItem('access-timer',expires)
    localStorage.setItem('refresh',refresh)
    console.log(localStorage)
    const elem = document.getElementById("diagrafi");
    elem.style.color = '#abafb2';
    const elem2 = document.getElementById("new");
    elem2.style.color = '#abafb2';
    setButton(true)
    setDisabled(true)
  }


  return (
    <div className="statement-intro2">
        <div className="wrapperpage">
            <div id="wrapper">
                <BarTwo headerName={<Text>agreedstatement</Text>} />
                <div className="div226">
                    <div className="div227">
                        <div className="div228"><Text>agreedstatementscenedetails</Text></div>
                        <div className="div229"><Text>fieldswithasterisk</Text></div>
                    </div>
                    <div className="frame-parent112">
                        <div className="listnew-statement-parent4">
                            <div className="listnew-statement30" onClick={onListnewStatementContainerClick}>
                                <div style={{fontWeight:'600'}} className="qwert30"><Text>vehicleb</Text>*&nbsp;<Text>vehiclebyour</Text></div>
                                {localStorage.getItem('tilefono_B_odigou')? <img className="insideicon27" alt="" src={okicon} />: <img className="insideicon27" alt="" src="/insideicon.svg" />}
                               
                            </div>
                            <div className="listnew-statement30" onClick={onClickAccidentDescription}>
                                <div style={{fontWeight:'600'}} className="qwert30"><Text>accidentdescription</Text>*</div>
                                {localStorage.getItem('geolat')? <img className="insideicon27" alt="" src={okicon} />: <img className="insideicon27" alt="" src="/insideicon.svg" />}

                            </div>
                        </div>
                        <div className="listnew-statement-parent4">
                            <div className="listnew-statement30" onClick={onClickInjured}>
                                <div className="qwert30"><Text>injuriesif</Text></div>
                                {localStorage.getItem('travmaties')? <img className="insideicon27" alt="" src={okicon} />: <img className="insideicon27" alt="" src="/insideicon.svg" />}

                            </div>
                            <div className="listnew-statement30" onClick={onClickWhitness}>
                                <div className="qwert30"><Text>witnessesif</Text></div>
                                {localStorage.getItem('martires')? <img className="insideicon27" alt="" src={okicon} />: <img className="insideicon27" alt="" src="/insideicon.svg" />}
                            </div>
                            <div className="listnew-statement30" onClick={onClickFirst}>
                                <div className="qwert30"><Text>vehiclea</Text>&nbsp;<Text>vehicleayour</Text></div>
                                {localStorage.getItem('ar_diplomatos_A_odigou')? <img className="insideicon27" alt="" src={okicon} />: <img className="insideicon27" alt="" src="/insideicon.svg" />}
                            </div>
                        </div>
                    </div>
                    <div className="delete7">
                        <div className="button75">
                            <div className="qwert30"><Text>confirmation</Text></div>
                        </div>
                        <div className="button76">
                            <div className="button-inner5">
                                <div className="frame-parent113" style={{cursor:'pointer'}} >
                                    <div className="wrapper67">
                                        <button id='diagrafi' disabled={disabled}  onClick={DeleteAll} className="div230"><Text>deleteall</Text>
                                    
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill= "white"
                                    id='new'
                                    class="bi bi-google"
                                    viewBox="0 0 16 15"
                                    >
                                    <path d="M2.375 3.75H3.625H13.625" stroke="#A5A9AC" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.5 3.75V2.5C5.5 2.16848 5.6317 1.85054 5.86612 1.61612C6.10054 1.3817 6.41848 1.25 6.75 1.25H9.25C9.58152 1.25 9.89946 1.3817 10.1339 1.61612C10.3683 1.85054 10.5 2.16848 10.5 2.5V3.75M12.375 3.75V12.5C12.375 12.8315 12.2433 13.1495 12.0089 13.3839C11.7745 13.6183 11.4565 13.75 11.125 13.75H4.875C4.54348 13.75 4.22554 13.6183 3.99112 13.3839C3.7567 13.1495 3.625 12.8315 3.625 12.5V3.75H12.375Z" stroke="#A5A9AC" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.75 6.875V10.625" stroke="#A5A9AC" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9.25 6.875V10.625" stroke="#A5A9AC" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg></button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="button-wrapper38">
                    <button className="button73" disabled={button} onClick={onClickEpiskopisi} style={{color:'white'}}>
                        <div className="continue53"><Text>preview</Text></div>
                    </button>
                </div>
            </div>
        </div>
    </div>
);

};

export default StatementIntro2;
