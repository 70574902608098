import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Accident18.css";
import BarTwo from "./Bar-two";
import MapContainer from '../components/GoogleMap.js';
import { Text } from "./../components/Translate.js";
import html2canvas from 'html2canvas';
import Modal from "../components/ModalAccident";
import "../css/Accident13.css";


const Accident18 = () => {
  const navigate = useNavigate();
  const [ModalAccident, setModalAccident] = useState(false);

  const AccidentModal = () => {
 
    setModalAccident(!ModalAccident)
   
  }

  return (
      <div className="accident18">
          <div className="wrapperpage">
              <div id="wrapper">
                  <BarTwo headerName={<Text>accidentdescription</Text>} />
                  <div className="progress-bar-parent14">
                      <div className="progress-bar16">
                          <div className="progress-bar-child93" />
                          <div className="progress-bar-child93" />
                          <div className="progress-bar-child95" />
                          <div className="progress-bar-child95" />
                          <div className="progress-bar-child95" />
                          <div className="progress-bar-child95" />
                      </div>
                      <div className="text7">
                          <div className="div93"><Text>confirmaccidentlocation</Text></div>
                          <div className="pin1" id='pin1'><Text>changelocationpin</Text></div>
                      </div>
                      <div className="frame-parent60">
                          <MapContainer />
                      </div>
                  </div>
                  <Modal state="αποθηκεύτηκε" show={ModalAccident} close={AccidentModal} onHide={() => setModalAccident(false)}></Modal>
                  <div className="button-wrapper38">
                      <div className="button73" onClick={() => AccidentModal(true)}>
                          <div className="continue23"><Text>next</Text></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default Accident18;
