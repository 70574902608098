import { useCallback, useState, setState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Accident1.css";
import BarTwo from "./Bar-two";
import { Text } from "./../components/Translate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons';



const initialState = {
    date: (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, -8)
  }

const Accident1 = () => {
  const navigate = useNavigate();
  const [{ date}, setState] = useState(initialState)
  
  const onButtonContainerClick = useCallback(() => {
    navigate("/accident1");
  }, [navigate]);
  const handleChange3 = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const handleSubmit3 = (e) => {
    e.preventDefault()
    
    localStorage.setItem("accident_datetime", date );
    onButtonContainerClick()
  }
  useEffect(() => {
    
    // Initialization code comes here
    if (date===null){
    setState({date:localStorage.getItem("accident_datetime")})
    }
}, []);

  return (
      <div className="accident1">
          <div className="wrapperpage">
              <div id="wrapper">
                  <BarTwo headerName={<Text>accidentdescription</Text>} />
                  <div className="progress-bar-group">
                      <div className="progress-bar1">
                          <div className="progress-bar-child3" />
                          <div className="progress-bar-child4" />
                          <div className="progress-bar-child4" />
                          <div className="progress-bar-child4" />
                          <div className="progress-bar-child4" />
                          <div className="progress-bar-child4" />
                      </div>
              

                      <form style={{}}
                           validate onSubmit={handleSubmit3}
                        >
                      <div className="div10"><Text>datetimeofaccident</Text></div>
                      <br/>
                      <div className="time-and-date" >
                          <div className="input2" >
                              <div className="input-title"></div>
                              <input
                                                type="datetime-local"
                                                className="input-wrapper180"
                                                id='date'
                                                value={date}
                                                name='date'
                                                required      
                                                onChange={handleChange3}/>
                          </div>
                      </div>
                      
                      <div className="button-wrapper38">
                    <button type='submit' id='button75' className="button73" style={{width:'288px',color:'white'}} ><Text>next</Text></button>
                    </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
   );
};

export default Accident1;
