import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useCallback,setState } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from './../components/Translate';
import carb from '../assets/driver a-1.svg'
import cara from '../assets/driver a-4.svg'
import motor from '../assets/frame-2948.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons';

function ModalInfo(props) {
 
  const navigate = useNavigate();
  const date = new Date();
  const currentDate = date.toISOString()
  console.log(currentDate)
  const onGotoNextPage = useCallback(() => {
    onButtonContainerClick2()
    navigate("/accident12");
  }, [navigate]);

  const onshare = useCallback(() => {
    navigate("/share");
  }, [navigate]);

  const onButtonContainerClick2 =() =>{
    const headers = new Headers({
      'Authorization': `Bearer ${localStorage.getItem('access')}` ,
      'Content-Type': 'application/json'
  });
  const requestOptions = {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${localStorage.getItem('access')}` },
};
  
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims/`, requestOptions)
 .then((response) =>{
  return response.json();  
}).then((data) =>{ 
  console.log(data) 
  if (data.length == 0){
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims/`, { 
      method: 'POST',
      headers:headers, 
      mode: 'cors', 
      body: JSON.stringify({
        type: "ACCIDENT",
        category: "MUTUAL_SETTLEMENT",
        createdAt: currentDate,
        accidentDateTime: localStorage.getItem("accident_datetime"),
        status: "PENDING", // can be PENDING, IN_PROGRESS, COMPLETED
        location: {
        address: localStorage.getItem("center"),
        lat:  localStorage.getItem("lat"),
        long: localStorage.getItem("lng")
        },
      
        })
    }).then(response => {
      
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  
  }
  if(data.length!==0){
  if(data[data.length-1].status==='COMPLETE'){

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/claims/`, { 
      method: 'POST',
      headers:headers, 
      mode: 'cors', 
      body: JSON.stringify({
        type: "ACCIDENT",
        category: "MUTUAL_SETTLEMENT",
        createdAt: currentDate,
        accidentDateTime: localStorage.getItem("accident_datetime"),
        status: "PENDING", // can be PENDING, IN_PROGRESS, COMPLETED
        location: {
        address: localStorage.getItem("center"),
        lat:  localStorage.getItem("lat"),
        long: localStorage.getItem("lng")
        },
      
        })
    }).then(response => {
      
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }}
 })
 

   
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
   
      <Modal.Body>
      <div className="iconyes-parent2">
        <div style={{float:'right',width:'100%'}}>
      <FontAwesomeIcon style={{float:'right',cursor:'pointer'}} icon={faRectangleXmark} onClick={(props.close)}/></div>
        <div className="parent39" style={{paddingTop:'0px'}}>
       
          <b className="b44"><Text>accidentrecreation</Text></b>
     
          <div className="div69" style={{padding:'0px 20px'}}>
              <Text>accidentsketch</Text>
            </div>
            <div className="frame-parent40">
              <div className="frame-parent41">
                <div className="group-wrapper2">
                <div className="group1">
                    <img className="vector-icon28" alt="" src={cara} style={{height:'auto',width:'70px',top:'0px',left:'0px'}}/>
                   
                  </div>
                </div>
                <div className="div70">
                  <Text>vehiclealocation</Text>
                </div>
              </div>
              <div className="frame-parent41">
                <div className="group-wrapper2">
                  <div className="group1">
                    <img className="vector-icon28" alt="" src={carb} style={{height:'auto',width:'50px',top:'0px',left:'0px'}}/>
                   
                  </div>
                </div>
                <div className="div70">
                  <Text>vehicleblocation</Text>
                </div>
              </div>
              <div className="frame-parent43">
              <div className="group-wrapper2">
                  <div className="group1">
                    <img className="vector-icon28" alt="" src={motor} style={{height:'auto',width:'50px',top:'0px',left:'0px'}}/>
                   
                  </div>
                </div>
                <div className="qwerty111">
                  <Text>elements</Text>
                </div>
              </div>
            </div>
          
         
        </div>
      </div>
      </Modal.Body>
      <Modal.Footer style={{justifyContent:'center'}}>
        <Button className='button73' onClick={onGotoNextPage}><Text>next</Text></Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ModalInfo;