import { useCallback,useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Share.css";
import BarTwo from "./Bar-two";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ModalInfo from "../components/Modal";
import { Text } from "./../components/Translate";

const Share = () => {
  const navigate = useNavigate();
  const [count,setCount]= useState(1)

  const onButtonContainerClick = () => {
    setModalShare(true)
  }
  const [formValues, setFormValues] = useState([{ name: "", email : ""}])
  const [modalShare, setModalShare] = useState(false);



  const ShareForm = () => {
    setModalShare(true)
    console.log(5);
  }
  

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  }
  let addFormFields = () => {
    setFormValues([...formValues, { name: "", email: "" }])
    setCount(count + 1);
    console.log(count)
  }

let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
}

let handleSubmit = (event) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
}

  return (
    <div className="share6">
     <div className="wrapperpage">

<div id="wrapper">
      <BarTwo headerName={<Text>agreedstatement</Text>} />
      <div className="frame-parent92">
        <div className="parent42">
          <div className="div201"><Text>sharestatement</Text></div>
          <div className="div202">
            <Text>detailsofpersonstoshare</Text>
          </div>
        </div>
        <form  onSubmit={handleSubmit}>
          {formValues.map((element, index) => (
            <div className="form-inline" key={index}>
              <Accordion className="infopersonalexpand13">
      
      <AccordionSummary
        expandIcon={ <FontAwesomeIcon icon={faChevronDown} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography> 
          <div className="icon-parent20">
          
          <div className="div343"><Text>recipient</Text> {index+1}</div>
        </div></Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        <div className="frame-parent" >
          <div className="input-parent47">
            <div className="input342">
              <div className="input-title185"><Text>mobileoremail</Text></div>
              <input className="input-wrapper180"></input>
            </div>
          </div>
          <div className="frame-parent102">
          <div className="frame-parent103">
            
     
             <div className="icontheft-parent">
                 <div class="radio-item">   
                   <input type="radio" name={index} id={index-3}/>
                   <label for={index-3}>
                    <Text>involvedinaccident</Text>
                   </label>
              
             </div>
           </div>
           <div>
             <div className="icontheft-parent">
             <div class="radio-item">   
             <input type="radio" name={index} id={index+1}/>
                   <label for={index+1}>
                    <Text>involvedinaccident</Text>
                   </label>
                 </div>
             </div>
             </div>
           </div>
         </div>
        </div>
        <div className="input-parent47" style={{padding:'20px 0px 20px 0px'}}>              
              </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
    <br/>
              {
                index ? 
                <div className="list5" onClick={() => removeFormFields(index)}>
            <img className="no-copy-24" alt="" src="/no-copy-3.svg" />
          </div>
                : null
              }
              <br/>
            </div>
          ))}
          
      </form>
     
      <div className="list5" onClick={() => addFormFields()}>
            <img className="no-copy-24" alt="" src="/no-copy-2.svg" />
          
        
      </div>
     
      <div className="button-wrapper38">
        <div className="button73" onClick={onButtonContainerClick}>
          <div className="continue53"><Text>send</Text></div>
        </div>
      </div>
      <ModalInfo state='κοινοποιήθηκε'
        show={modalShare}
        onHide={() => setModalShare(false)}
          ></ModalInfo>
        
       
      </div>
    </div>
    </div>
   
    </div>
  );
};

export default Share;
