import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./Translate"; 


function ModalInfo(props) {
  const navigate = useNavigate();

  const onGotoStart = useCallback(() => {
    navigate("/statement-intro4");
  }, [navigate]);


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
      <div className="iconyes-parent2">
        <img className="iconyes4" alt="" src="/iconyes3.svg" />
        <div className="parent39" style={{textAlign:'center'}}>
          <b className="b44"><Text>firstinfoecomplete</Text></b>
          <div className="div69" style={{textAlign:'center'}}>
            <Text>continueifyouwish</Text>
          </div>
        </div>
      </div>
      </Modal.Body>
      <Modal.Footer style={{justifyContent:'center'}}>
        <Button className='button73' onClick={onGotoStart}><Text>okay</Text></Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ModalInfo;