import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./../components/Translate";
import "./Illustration1.css";
import info3 from '../assets/illustration3.svg'
import Bar3 from "../pages/Bar-three";


const Illustration2 = () => {
  const navigate = useNavigate();
  const onIllustration2ContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);
  return (
    <div className="wrapperpage" style={{backgroundColor:'white'}}>
    <div class="h-100 d-flex align-items-center justify-content-center">
    <div id="wrapper">
      <Bar3 headerName={<Text>howitworks</Text>}/>
    <div className="illustration2" onClick={onIllustration2ContainerClick}>
      <section className="illustration-info-wrapper">
        <img
          className="illustration-info-icon"
          loading="eager"
          alt=""
          src={info3}
        />
      </section>
      <section className="text-inputs">
        <div className="div389"><Text>addphotosanddescription</Text></div>
        <div className="div390">
         <Text>describetheincident</Text>
        </div>
      </section>
      <img className="notch-icon1" alt="" src="/notch@2x.png" />
      <img
        className="recording-indicator-icon1"
        alt=""
        src="/recording-indicator.svg"
      />
      <div className="ellipse-cluster-wrapper">
        <div className="ellipse-cluster">
          <div className="text-inputs-group" />
          <div className="text-inputs-group1" />
          <div className="text-inputs-group2" />
        </div>
      </div>
    </div>
    </div>
      </div>
    </div>
  );
};

export default Illustration2;
