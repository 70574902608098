import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/Homepage.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';




const Bar3 = (props) => {
  const navigate = useNavigate();

  const onClickContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);
  const onClickContainerClick2 = useCallback(() => {
    if(props.headerName!=='Homepage') {navigate(-1)}
  }, [navigate]);
  return (   
    <div className="homepage1">
      <b>{props.headerName}</b>
      <img className="arrow-header-2-icon51" alt="" src="/arrow-header-2.svg" onClick={() =>onClickContainerClick2()} />
      <FontAwesomeIcon icon={faXmark} onClick={onClickContainerClick} style={{cursor:'pointer',float:'right',color:'#46bedc',width:'40px',height:'20px',position:'relative',top:'0px'}}/>     </div>
  );
};

export default Bar3;
//      <FontAwesomeIcon icon={faXmark} onClick={onClickContainerClick} style={{cursor:'pointer',float:'right',color:'#46bedc',width:'40px',height:'20px',position:'relative',top:'0px'}}/> 
