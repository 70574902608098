import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../css/StatementIntro.css";
import Bar from "./Bar";
import BarTwo from "./Bar-two";
import { Text } from "./../components/Translate";
import breakage from "../assets/breakage.svg"
const StatementIntro = () => {
  const navigate = useNavigate();

  const onFrameContainer5Click = useCallback(() => {
    localStorage.setItem("simvan", 'Ατύχημα');
    navigate("/statement-intro1");
  }, [navigate]);

  return (
    <div className="wrapperpage">
        <div id="wrapper">
            <BarTwo headerName={<Text>newstatement</Text>} />
            <div className="parent48">
                <b className="b54"><Text>whathappened</Text></b>

                <div class="radio-list">
                    <div className="frame-wrapper10">
                        <div className="frame-parent102">
                            <div className="frame-parent103">
                                <div className="icontheft-parent">
                                    <div class="radio-item">
                                        <input type="radio" name="radio" id="radio1" disabled />
                                        <label for="radio1">
                                           <span className="label4"><Text>theft</Text></span> 
                                            <img className="icontheft" alt="" src="/icontheft.svg" />
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div className="icontheft-parent">
                                        <div class="radio-item">
                                            <input type="radio" name="radio" id="radio2" checked />
                                            <label for="radio2">
                                            <span className="label4"><Text>accidentstate</Text></span> 
                                                <img className="icontheft" alt="" src="/crash.svg" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-parent103">
                                <div className="icontheft-parent">
                                    <div class="radio-item">
                                        <input type="radio" name="radio" id="radio3" disabled />
                                        <label for="radio3">
                                        <span className="label4"><Text>crystallbreakage</Text></span> 
                                            <img className="icontheft" alt="" src={breakage} />
                                        </label>
                                    </div>
                                </div>

                                <div className="icontheft-parent">
                                    <div class="radio-item">
                                        <input type="radio" name="radio" id="radio4" disabled />
                                        <label for="radio4">
                                        <span className="label4"> <Text>other</Text></span> 
                                            <img className="icontheft" alt="" src="/iconother.svg" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="button-wrapper38">
            <div className="button73" onClick={onFrameContainer5Click}>
                <div className="continue53"><Text>next</Text></div>
            </div>
        </div>
    </div>
);

};

export default StatementIntro;
