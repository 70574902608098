import { useCallback,useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/NdDriver8.css";
import Bar from "./Bar";
import BarTwo from "./Bar-two";
import { Text } from "./../components/Translate";
import ModalInfo from "../components/ModalVehicleB";

const NdDriver8 = () => {
  const navigate = useNavigate();
  const [driver, setDriver] = useState('Ιδιοκτήτης');
  const [ModalComplete, setModalComplete] = useState(false);

  const CompleteModal = () => {
    setModalComplete(true)
  }
  const onButtonContainerClick = useCallback(() => {

    navigate("/2nd-driver6");
    
  }, [navigate]);

  function NextPage2(e) {
    if (driver !== ''){
        localStorage.setItem("Odigos_B_oximatos", driver );
        console.log(driver)
        if(driver==='Ιδιοκτήτης'){
            CompleteModal()
        }else{
            onButtonContainerClick()
        }
    }
  }
  useEffect(() => {
    if(localStorage.getItem("Odigos_B_oximatos")){
        setDriver(localStorage.getItem("Odigos_B_oximatos"))
    }

   
  })


  return (
    <div className="nd-driver8">
        <div className="wrapperpage">
            <div id="wrapper">
                <BarTwo headerName={<Text>vehiclebdetails</Text>} />
                <div className="progress-bar-parent32">
                    <div className="progress-bar38">
                        <div className="progress-bar-child161" />
                        <div className="progress-bar-child161" />
                        <div className="progress-bar-child161" />
                        <div className="progress-bar-child164" />
                    </div>
                    <div className="wrapper98">
                        <div className="div352"><Text>driverattimeofaccident</Text>&nbsp;<Text>vehicleowner</Text></div>
                    </div>
                    <div className="frame-parent102">
                        <div className="frame-parent103">
                            <div className="icontheft-parent" onClick={() => {setDriver('Ιδιοκτήτης') }}>
                                    <div class="radio-item">
                                        <input type="radio" name="radio" id="radio1" checked={driver === 'Ιδιοκτήτης' ||  localStorage.getItem("Odigos_B_oximatos") === 'Ιδιοκτήτης'}/>
                                        <label for="radio1"><Text>yes</Text></label>
                                    </div>
                            </div>
                       
                            <div className="icontheft-parent" onClick={() => {setDriver("false") }}>
                                    <div class="radio-item">
                                        <input type="radio" name="radio" id="radio2" checked={driver === 'false' ||  localStorage.getItem("Odigos_B_oximatos") === 'false'}/>
                                        <label for="radio2"><Text>no</Text></label>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="button-wrapper38">
                    <div className="button73" onClick={NextPage2}>
                        <div className="continue74"><Text>next</Text></div>
                    </div>
                </div>
            </div>
            <ModalInfo show={ModalComplete} text='4rdsection'onHide={() => setModalComplete(false)}></ModalInfo>
        </div>
    </div> 
  );
};

export default NdDriver8;
