import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Witness2.css";
import BarTwo from "./Bar-two";
import { Text } from "./../components/Translate";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import chevron from '../assets/insideicon.svg'
import ModalInfo from "../components/Modal3";

const Witness2 = () => {
    const navigate = useNavigate();
    const [count,setCount]= useState(1)
    const [ModalComplete, setModalComplete] = useState(false);
    const [count2,setCount2]= useState(1)
    const [formValues, setFormValues] = useState([{ eponimo: "", onoma: "", tilefono:"",id:count  }]);



    
    const CompleteModal = (e) => {
        if(JSON.stringify(formValues).includes(`"eponimo":""`) || JSON.stringify(formValues).includes(`"onoma":""`) || JSON.stringify(formValues).includes(`"tilefono":""`)){
        

        }else{
        handleSubmit(e)
        setModalComplete(true)
        }
        
     }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    };

    let addFormFields = () => {
       
        setFormValues([...formValues, { eponimo: "", onoma: "", tilefono:"",id:count+1  }]);
        setCount(count + 1);
        console.log(count)
    };

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    useEffect(() => {
        // Initialization code comes here

        let witness = JSON.parse(localStorage.getItem("martires"))
     
        if(witness !== null){
          console.log(witness)
        setFormValues([{ eponimo: witness[0].eponimo, onoma: witness[0].onoma, tilefono:witness[0].tilefono, id:'1'}])
        if(witness.length == 2){
            setFormValues([{ eponimo: witness[0].eponimo, onoma: witness[0].onoma, tilefono:witness[0].tilefono,id:'1'},
                { eponimo: witness[1].eponimo, onoma: witness[1].onoma, tilefono:witness[1].tilefono,id:'2'}])    
        }
        if(witness.length == 3){
            setFormValues([{ eponimo: witness[0].eponimo, onoma: witness[0].onoma, tilefono:witness[0].tilefono,id:'1'},
                { eponimo: witness[1].eponimo, onoma: witness[1].onoma, tilefono:witness[1].tilefono,id:'2'},
                { eponimo: witness[2].eponimo, onoma: witness[2].onoma, tilefono:witness[2].tilefono,id:'3'},
            ])    
        }
        if(witness.length == 4){
            setFormValues([{ eponimo: witness[0].eponimo, onoma: witness[0].onoma, tilefono:witness[0].tilefono,id:'1'},
                { eponimo: witness[1].eponimo, onoma: witness[1].onoma, tilefono:witness[1].tilefono,id:'2'},
                { eponimo: witness[2].eponimo, onoma: witness[2].onoma, tilefono:witness[2].tilefono,id:'3'},
                { eponimo: witness[3].eponimo, onoma: witness[3].onoma, tilefono:witness[3].tilefono,id:'4'},
            ])    
        }
        if(witness.length == 5){
            setFormValues([{ eponimo: witness[0].eponimo, onoma: witness[0].onoma, tilefono:witness[0].tilefono,id:'1'},
                { eponimo: witness[1].eponimo, onoma: witness[1].onoma, tilefono:witness[1].tilefono,id:'2'},
                { eponimo: witness[2].eponimo, onoma: witness[2].onoma, tilefono:witness[2].tilefono,id:'3'},
                { eponimo: witness[3].eponimo, onoma: witness[3].onoma, tilefono:witness[3].tilefono,id:'4'},
                { eponimo: witness[4].eponimo, onoma: witness[4].onoma, tilefono:witness[4].tilefono,id:'5'}
            ])    
        }

    }
    }, []);
   

    let handleSubmit = (event) => {
        if(document.getElementById('numberphone').value){
        console.log(localStorage);
        event.preventDefault();
        localStorage.setItem("martires", JSON.stringify(formValues));
        console.log(formValues);
    }
    }
    return (
        <div className="witness2">
            <div className="wrapperpage">
                <div id="wrapper">
                    <BarTwo headerName={<Text>witnesses</Text>} />
                    <div className="frame-parent186">
                        <div className="wrapper104">
                            <div className="div228"><Text>wtinessesdetails</Text></div>
                        </div>

                        <form onSubmit={handleSubmit}>
                            {formValues.map((element, index) => (
                                <div className="form-inline" key={index}>
                                    <Accordion className="infopersonalexpand13">
                                        <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography>
                                                <div className="icon-parent20">
                                                    <img className="icon38" alt="" src="/iconwitness.svg" />
                                                    <div className="div343"><Text>witness</Text> {index + 1}</div>
                                                </div>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <div className="frame-parent156">
                                                    <div className="input-parent47">
                                                        <div className="input342">
                                                            <div className="input-title185"><Text>surname</Text></div>
                                                            <input
                                                            className="input-wrapper180"
                                                            type="text"
                                                            name="eponimo"
                                                            required
                                                            value={element.eponimo}
                                                            onChange={event => handleChange(index,event)}
                                                            />
                                                        </div>
                                                        <div className="input342">
                                                            <div className="input-title185"><Text>name</Text></div>
                                                            <input
                                                            className="input-wrapper180"
                                                            type="text"
                                                            name="onoma"
                                                            value={element.onoma}
                                                            onChange={event => handleChange(index,event)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-parent47">
                                                        <div className="input342">
                                                            <div className="input-title185"><Text>phonenumber</Text>*</div>
                                                            <input
                                                            id='numberphone'
                                                            className="input-wrapper180"
                                                            type="text"
                                                            name="tilefono"
                                                            required
                                                            value={element.tilefono}
                                                            onChange={event => handleChange(index,event)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-parent47" style={{ padding: "20px 0px 20px 0px" }}></div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <br />
                                    {index ? (
                                        <div className="list5" onClick={() => removeFormFields(index)}>
                                            <img className="no-copy-24" alt="" src="/no-copy-3.svg" />
                                        </div>
                                    ) : null}
                                    <br />
                                </div>
                            ))}
                        </form>
                    </div>
                    
                    <div className="list5" onClick={() => addFormFields()}>
                        <img className="no-copy-24" alt="" src="/no-copy-2.svg" />
                    </div>

                    <div className="button-wrapper38">
              <div className="button73" onClick={CompleteModal}>
                  <div className="continue53"><Text>next</Text></div>
              </div>
          </div>
          <div className="date-picker5">
              <div className="selection-row5"></div>
          </div>
          <ModalInfo show={ModalComplete} text='4rdsection' onHide={() => setModalComplete(false)}></ModalInfo>
                </div>
            </div>
        </div>
    );
};

export default Witness2;
