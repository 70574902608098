import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/Homepage.css";
const BarTwo = (props) => {

    const navigate = useNavigate();

    const onGotoMenu = useCallback(() => {
      navigate("/statement-intro4");
    }, [navigate]);
    
  return (
      <div className="homepage1" style={{display:'flex', flexDirection:'row'}}>
        
          <img className="arrow-header-2-icon51" alt="" src="/arrow-header-2.svg" style={{position:'relative',top:'-4px'}} onClick={() => navigate(-1)} />
          <div id='hlength' style={{width:'100%',marginRight:'20px'}}>
          <b>{props.headerName}</b></div>
         
      </div>
  );
};

export default BarTwo;

/*
 <div className="frame48" onClick={onGotoMenu}>
              <img className="group-icon157" alt="" src="/group.svg" />
              <img className="group-icon157" alt="" src="/group1.svg" />
              <img className="group-icon159" alt="" src="/group2.svg" />
          </div>
*/