import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./../components/Translate";


function ModalInfo(props) {
  const navigate = useNavigate();

  const onGotoStart = useCallback(() => {
    if(props.state==='στάλθηκε'){
      console.log(localStorage)
      localStorage.removeItem("Ar_kikloforias_B_oximatos");
      localStorage.removeItem("Odigos_B_oximatos");
      localStorage.removeItem("Onoma_B_odigou");
      localStorage.removeItem("accident_datetime");
      localStorage.removeItem("anafora_astunomias");
      localStorage.removeItem("ar_diplomatos_B_odigou");
      localStorage.removeItem("ar_prasinis_B_odigou");

      localStorage.removeItem("ar_simvolaiou_B_odigou");
      localStorage.removeItem("asfalistiki_B_odigou");
      localStorage.removeItem("center");
      localStorage.removeItem("diefthinsi_B_odigou");
      localStorage.removeItem("email_B_odigou");
      localStorage.removeItem("emplekomena_oximata");
      localStorage.removeItem("eponimo_B_odigou");

      localStorage.removeItem("geolat");
      localStorage.removeItem("geolng");
      localStorage.removeItem("im_gennisis_B_odigou");
      localStorage.removeItem("isxus_diplomatos_B_odigou");
      localStorage.removeItem("katigoria_diplomatos_B_odigou");
      localStorage.removeItem("ar_diplomatos_B_odigou");
      localStorage.removeItem("lng");
      localStorage.removeItem("lat");

      localStorage.removeItem("marka");
      localStorage.removeItem("montelo");
      localStorage.removeItem("oxima_B_odigou");
      localStorage.removeItem("settlement-id");
      localStorage.removeItem("simvan");
      localStorage.removeItem("tilefono_B_odigou");
      localStorage.removeItem("tk_B_odigou");

      localStorage.removeItem("xora_B_odigou");
      localStorage.removeItem("xroma");
   
    }
    navigate("/home");
  }, [navigate]);

  const onshare = useCallback(() => {
    navigate("/share");
  }, [navigate]);

  const onshavePDF = () => {
    console.log('download')
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
   
      <Modal.Body>
      <div className="iconyes-parent2">
        <img className="iconyes4" alt="" src="/iconyes3.svg" />
        <div className="parent39">
          <b className="b44">
            <Text>
              {
                props.state === "στάλθηκε" ? 'statementsubmitted' :
                  props.state === "αποθηκεύτηκε" ? 'statementsaved' : 
                    props.state === "κοινοποιήθηκε" ? 'statementshared' : null
              }
            </Text>
          </b>
         
          <div className="share2" >
            <div className="button61">
              <div className="continue47"><Text>confirmation</Text></div>
            </div>
          
          </div>
        </div>
      </div>
      </Modal.Body>
      <Modal.Footer style={{justifyContent:'center'}}>
        <Button className='button73' onClick={onGotoStart}><Text>home</Text></Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ModalInfo;

/*
<div className="button60" onClick={onshavePDF}>
            <div className="pdf-group">
              <div className="div198"><Text>savepdf</Text></div>
              <img className="frame-child76" alt="" src="/frame-2556.svg" />
            </div>
          </div>


<div className="button62" onClick={onshare}>
<div className="pdf-group">
  <div className="div198"><Text>share</Text></div>
  <img className="frame-child76" alt="" src="/frame-2950.svg" />
</div>
</div>

*/