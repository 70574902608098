import React, { useState, useEffect } from "react";
import { Stage, Layer, Image, Transformer, Circle, Line } from "react-konva";
import useImage from "use-image";
import '../css/Accident.css'
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./../components/Translate";
import left from "../assets/turn-left.png";
import right from "../assets/turn-right.png";
import car1 from "../assets/car a.png";
import car2 from "../assets/car b.png";
import moto1 from "../assets/moto a.png";
import moto2 from "../assets/moto b.png";
import truck1 from "../assets/truck a.png";
import truck2 from "../assets/truck b.png";
import person from "../assets/Group.png";
import othercar from "../assets/other car.png";
import othermoto from "../assets/moto.png";
import othertruck from "../assets/truck.png";
import bus from "../assets/bus.png";
import rightleft from "../assets/mandatory-direction-ahead-turn-left-right.png";
import roundabout from "../assets/mandatory-direction-roundabout.png";
import prohcar from "../assets/prohibited-access-car.png";
import noentry from "../assets/prohibited-access-entry.png";
import stop from "../assets/warning-crossroad-stop.png";
import proleft from "../assets/prohibited-action-turn-left.png";
import proright from "../assets/prohibited-action-turn-right.png";
import turnu from "../assets/prohibited-action-turn-u.png";
import lights from "../assets/warning-traffic-lights.png";
import { createRef } from "react";
import * as htmlToImage from "html-to-image";
import { useScreenshot } from 'use-react-screenshot'
import html2canvas from "html2canvas";
import OpenStreetMap from "./OpenStreetMap";

const createFileName = (extension = "", ...names) => {
    if (!extension) {
      return "";
    }
  
    return `${names.join("")}.${extension}`;
  };

const URLImage = ({ image, shapeProps, unSelectShape, isSelected, onSelect, onChange, stageScale, onDelete }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const deleteButton = React.useRef();
    const [img] = useImage(image.src);

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);
    const onMouseEnter = (event) => {
        if (isSelected) {
            event.target.getStage().container().style.cursor = "move";
        }
        if (!isSelected) {
            event.target.getStage().container().style.cursor = "pointer";
        }
    };

    const onMouseLeave = (event) => {
        event.target.getStage().container().style.cursor = "default";
    };

    const handleDelete = () => {
        unSelectShape(null);
        onDelete(shapeRef.current);
    };

    return (
        <React.Fragment>
            <Image
                image={img}
                x={image.x}
                y={image.y}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                // I will use offset to set origin to the center of the image
                offsetX={img ? img.width / 2 : 0}
                offsetY={img ? img.height / 2 : 0}
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    padding={1}
                    flipEnabled={false}
                    ref={trRef}
                    anchorCornerRadius={8}
                    anchorSize={10}
                    anchorStroke="#00C5C3"
                    borderStroke="#00C5C3"
                    borderStrokeWidth={2}
                    anchorStrokeWidth={2}
                    rotateAnchorOffset={30}
                    boundBoxFunc={(oldBox, newBox) => getBoundBox(oldBox, newBox, stageRef)}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                >
                    <Circle radius={8} fill="red" ref={deleteButton} onClick={handleDelete} onTap={handleDelete} x={shapeRef.current.width() * 0.3}></Circle>
                </Transformer>
            )}
        </React.Fragment>
    );
};

const DragandDrop = () => {
    
    const latitude = localStorage.getItem("lat");
    const longtitude = localStorage.getItem("lng");
    const dragUrl = React.useRef();
    const stageRef = React.useRef();
    const [images, setImages] = React.useState([]);
    const [selectedId, selectShape] = React.useState(null);
    //const stage = stageRef.current?.getStage();
    const [stageSpec, setStageSpec] = useState({
        scale: 1,
        x: 0,
        y: 0,
    });
    
  const ref = createRef(null);
  const navigate = useNavigate();

  const onFrameContainer10Click = useCallback(() => {
    html2canvas(document.querySelector('#map-area'),{allowTaint: true,
        useCORS: true}).then(canvas =>{
            const image = canvas.toDataURL("image/png", 1.0);
            localStorage.setItem("sxedio_atuximatos",image)
        })
    navigate("/accident14");
  }, [navigate]);

    const handleRemove = (index) => {
        const newList = images.filter((item) => item.index !== index);
        setImages(newList);
    };

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            selectShape(null);
        }
    };

    const unSelectShape = (prop) => {
        selectShape(prop);
    };
    const Deletes = (node) => {
        setImages([]);
        localStorage.removeItem("sxedio_atuximatos");
};
    const onDeleteImage = (node) => {
        const newImages = [...images];
        newImages.splice(node.index, 1);
        setImages(newImages);
    };


    return (
        <div>
            <div>
                <div>
                    <div>
                        <div>
                            <div className="justify-content-center">
                            </div>
                        </div>
                    </div>
                    <div className="button-wrapper38" style={{flexDirection:'row-reverse',padding:'0'}}>
                      <div className="button173" style={{}} onClick={Deletes}>
                          <div className="contine3" style={{textAlign:'center',fontSize:'12px'}}><Text>deletesketch</Text></div>
                      </div>
                  </div>
                  <br/>
                   <div id='map-area' style={{ position: "relative" }}>
                    <OpenStreetMap />
                    <div ref={ref} 
                        style={{ position: "absolute", top: "0" }}
                        onDrop={(e) => {
                            e.preventDefault();
                          //  console.log(latitude);
                         //   console.log(longtitude);
                            // register event position
                            stageRef.current.setPointersPositions(e);
                            
                            // add image
                            setImages(
                                images.concat([
                                    {
                                        ...stageRef.current.getRelativePointerPosition(),
                                        src: dragUrl.current,
                                    },
                                ])
                            );
                        }}
                        onDragOver={(e) => e.preventDefault()}
                        onTouchEnd={(e) => e.preventDefault()}
                    >

                        <Stage
                            width={800}
                            height={585}
                            onMouseDown={checkDeselect}
                            onTouchStart={checkDeselect}
                            style={{
                                border: "1px solid grey",
                                maxHeight: "40rem"
                            }}
                            ref={stageRef}
                            scaleX={stageSpec.scale * 0.3}
                            scaleY={stageSpec.scale * 0.3}
                            x={stageSpec.x}
                            y={stageSpec.y}
                        >
                            <Layer>
                                {images.map((image, index) => {
                                    return (
                                        <URLImage
                                            image={image}
                                            key={index}
                                            shapeProps={image}
                                            stageScale={stageSpec.scale}
                                            isSelected={image === selectedId}
                                            unSelectShape={unSelectShape}
                                            onClick={handleRemove}
                                            onTap={handleRemove}
                                            onSelect={() => {
                                                selectShape(image);
                                            }}
                                            onChange={(newAttrs) => {
                                                const rects = images.slice();
                                                rects[index] = newAttrs;
                                                setImages(rects);
                                            }}
                                            onDelete={onDeleteImage}
                                        />
                                    );
                                })}
                            </Layer>
                        </Stage>  
                    </div>
                   </div>
                </div>
                <br/>
                <div>
                    <div className="drivers-container2">
                        <div>
                    <p><Text>vehicles</Text></p>
                        <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img3"
                            alt="Car1"
                            src={car1}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img4"
                            alt="car2"
                            src={car2}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img15"
                            alt="Moto1"
                            src={moto1}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img16"
                            alt="moto2"
                            src={moto2}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                            <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img17"
                            alt="Truck1"
                            src={truck1}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img18"
                            alt="Truck2"
                            src={truck2}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                         <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img20"
                            alt="othercar"
                            src={othercar}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                         <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img21"
                            alt="othermoto"
                            src={othermoto}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                         <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img22"
                            alt="Bus"
                            src={bus}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                         <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img23"
                            alt="othertruck"
                            src={othertruck}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                         <img
                            id="signs"
                            height="40rem"
                            width="20rem"
                            objectfit="contain"
                            key="img19"
                            alt="human"
                            src={person}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        </div>
                        <br/>
                        <div>
                        <p><Text>roadsigns</Text></p>
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img1"
                            alt="left"
                            src={left}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;
                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img2"
                            alt="right"
                            src={right}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img5"
                            alt="rightleft"
                            src={rightleft}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img6"
                            alt="roundabout"
                            src={roundabout}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img7"
                            alt="prohcar"
                            src={prohcar}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img8"
                            alt="prohright"
                            src={proright}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img9"
                            alt="proleft"
                            src={proleft}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img10"
                            alt="turnu"
                            src={turnu}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img11"
                            alt="lights"
                            src={lights}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img12"
                            alt="noentry"
                            src={noentry}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        <img
                            id="signs"
                            height="40rem"
                            width="40rem"
                            objectfit="contain"
                            key="img13"
                            alt="stop"
                            src={stop}
                            draggable="true"
                            onDragStart={(e) => {
                                dragUrl.current = e.target.src;
                            }}
                            onTouchStart={(e) => {
                               
                                dragUrl.current = e.target.src;

                                setImages(
                                    images.concat([
                                        {
                                            ...stageRef.current.getRelativePointerPosition(e),
                                            src: dragUrl.current,
                                            x: 122,
                                            y: 270,
                                        },
                                    ])
                                );
                            }}
                        />
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-wrapper38">
                      <div className="button73" onClick={onFrameContainer10Click}>
                          <div className="continue23" style={{textAlign:'center',fontSize:'16px'}}><Text>next</Text></div>
                      </div>
                  </div>
                 
        </div>
    );
};

export default DragandDrop;
