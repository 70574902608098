import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./Translate";


function ModalValidate(props) {
  

  return (
    <Modal
      {...props}
      size="300"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
   
      <Modal.Body>
      <div className="iconyes-parent2">
      
        <div className="parent39">
          <b className="b44">
            <Text>
              Παρακαλώ συμπληρώστε <br/>τα υποχρεωτικά πεδία
            </Text>
          </b>
         
           
         
        </div>
      </div>
      </Modal.Body>
   
    </Modal>
  );
}
export default ModalValidate;