import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./../components/Translate";
import "../css/Shared.css";

const Shared = () => {
  const navigate = useNavigate();

  const onButtonContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onButtonContainer1Click = useCallback(() => {
    navigate("/saved");
  }, [navigate]);

  const onShareContainerClick = useCallback(() => {
    navigate("/share");
  }, [navigate]);

  return (
    <div className="shared">
      <b className="b45"><Text>agreedstatement</Text></b>
      <img className="arrow-header-2-icon39" alt="" src="/arrow-header-2.svg" />
      <div className="menu-overlay39">
        <div className="frame42">
          <img className="group-icon139" alt="" src="/group.svg" />
          <img className="group-icon139" alt="" src="/group1.svg" />
          <img className="group-icon141" alt="" src="/group2.svg" />
        </div>
      </div>
      <div className="button-wrapper35">
        <div className="button63" onClick={onButtonContainerClick}>
          <div className="pdf-container">
            <div className="div199"><Text>home</Text></div>
            <img className="frame-child78" alt="" src="/frame-139.svg" />
          </div>
        </div>
      </div>
      <div className="iconyes-parent3">
        <img className="iconyes5" alt="" src="/iconyes2.svg" />
        <div className="parent41">
          <b className="b46"><Text>statementshared</Text></b>
          <div className="button64" onClick={onButtonContainer1Click}>
            <div className="pdf-container">
              <div className="div199"><Text>savepdf</Text></div>
              <img className="frame-child79" alt="" src="/frame-2556.svg" />
            </div>
          </div>
          <div className="share4" onClick={onShareContainerClick}>
            <div className="button65">
              <div className="continue48"><Text>confirmation</Text></div>
            </div>
            <div className="button66">
              <div className="pdf-container">
                <div className="div199"><Text>share</Text></div>
                <img className="frame-child79" alt="" src="/frame-2950.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shared;
