import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";

function OpenStreetMap() {
  const latitude = localStorage.getItem("lat");
  const longitude = localStorage.getItem("lng");

  return (
    <div className="open-street-map">
      <MapContainer
        center={[latitude, longitude]}
        zoom={19}
        zoomControl={false}
        scrollWheelZoom={false}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: 0,
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxZoom={20}
        />
      </MapContainer>
    </div>
  );
}

export default OpenStreetMap;
