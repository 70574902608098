import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Homepage.css";

const Bar = (props) => {
  const navigate = useNavigate();

  const onClickContainerClick = useCallback(() => {
    navigate("/info");
  }, [navigate]);
  const onClickContainerClick2 = useCallback(() => {
    if (props.headerName !== "Homepage") {
      navigate(-1);
    }
  }, [navigate]);
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "29px",
          margin: "0 0 6px 0",
        }}
      >
        <b>{props.headerName}</b>
      </div>
      <img
        className="menu-overlay-icon"
        alt=""
        src="/menu-overlay.svg"
        style={{ position: "relative", top: "-44px" }}
        onClick={onClickContainerClick}
      />
    </>
  );
};

export default Bar;
//      <FontAwesomeIcon icon={faXmark} onClick={onClickContainerClick} style={{cursor:'pointer',float:'right',color:'#46bedc',width:'40px',height:'20px',position:'relative',top:'0px'}}/>
