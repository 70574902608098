import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./../components/Translate";
import "../css/Saved.css";

const Saved = () => {
  const navigate = useNavigate();

  const onButtonContainerClick = useCallback(() => {
    
    navigate("/");
  }, [navigate]);

  const onButtonContainer1Click = useCallback(() => {
    navigate("/saved");
  }, [navigate]);

  const onShareContainerClick = useCallback(() => {
    navigate("/share");
  }, [navigate]);

  return (
    <div className="saved">
      <b className="b41"><Text>agreedstatement</Text></b>
      <img className="arrow-header-2-icon37" alt="" src="/arrow-header-2.svg" />
      <div className="menu-overlay37">
        <div className="frame40">
          <img className="group-icon133" alt="" src="/group.svg" />
          <img className="group-icon133" alt="" src="/group1.svg" />
          <img className="group-icon135" alt="" src="/group2.svg" />
        </div>
      </div>
      <div className="button-wrapper33">
        <div className="button55" onClick={onButtonContainerClick}>
          <div className="pdf-parent">
            <div className="pdf"><Text>home</Text></div>
            <img className="frame-child72" alt="" src="/frame-139.svg" />
          </div>
        </div>
      </div>
      <div className="iconyes-parent1">
        <img className="iconyes3" alt="" src="/iconyes2.svg" />
        <div className="parent37">
          <b className="b42"><Text>statementsaved</Text></b>
          <div className="button56" onClick={onButtonContainer1Click}>
            <div className="pdf-parent">
              <div className="pdf"><Text>savepdf</Text></div>
              <img className="frame-child73" alt="" src="/frame-2556.svg" />
            </div>
          </div>
          <div className="share" onClick={onShareContainerClick}>
            <div className="button57">
              <div className="continue46"><Text>confirmation</Text></div>
            </div>
            <div className="button58">
              <div className="pdf-parent">
                <div className="pdf"><Text>share</Text></div>
                <img className="frame-child73" alt="" src="/frame-2950.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Saved;
