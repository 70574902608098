import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./../components/Translate";
import info2 from '../assets/illustration2.svg'
import "./Illustration11.css";

import Bar3 from "../pages/Bar-three";

const Illustration1 = () => {
  const navigate = useNavigate();

  const onIllustration3ContainerClick = useCallback(() => {
    navigate("/info3");
  }, [navigate]);

  return (
    <div className="wrapperpage" style={{backgroundColor:'white'}}>
    <div class="h-100 d-flex align-items-center justify-content-center">
    <div id="wrapper">
      <Bar3 headerName={<Text>howitworks</Text>}/>
    <div className="illustration3" onClick={onIllustration3ContainerClick}>
      <section className="illustration-info-wrapper">
        <img
          className="illustration-info-icon"
          loading="eager"
          alt=""
          src={info2}
        />
      </section>

      <section className="showcaseyourstory">
        <div className="div391">
          <Text>graphicrepresentationofaccident</Text>
        </div>
        <div className="div392">
          <Text>visuallyshowtheconditions</Text>
        </div>
      </section>
      <img className="notch-icon2" alt="" src="/notch@2x.png" />
      <img
        className="recording-indicator-icon2"
        alt=""
        src="/recording-indicator.svg"
      />
      <div className="error-frame">
        <div className="circular-frames">
          <div className="error-ellipse" />
          <div className="error-ellipse1" />
          <div className="error-ellipse2" />
        </div>
      </div>
    </div>
    </div>
      </div>
    </div>
  );
};

export default Illustration1;
