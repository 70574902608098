import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import React,{Component} from 'react'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import html2canvas from 'html2canvas';
import {saveAs} from "file-saver"; 
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import { Text } from "./../components/Translate";

Geocode.setApiKey("AIzaSyAGrKp0iO2wgSwsBq3k8j3SBjQxKoqOqks")


export class MapContainer extends Component {
    
  constructor(props) {
      super(props);
      this.state = {
          address: '',
          showingInfoWindow: false,
          activeMarker: {},
          selectedPlace: {},
          mapCenter: {
              lat: '47.9838',
              lng: '23.7275',
          },
      };
  }

  componentDidMount() {
    localStorage.setItem("geolat", "38.0104");
    localStorage.setItem("geolng", "23.7321");
    localStorage.setItem("lat", "38.0104");
    localStorage.setItem("lng", "23.7321");
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
    //    console.log("Latitude is  :", position.coords.latitude);
     //   console.log("Longitude is :", position.coords.longitude);
        localStorage.setItem("geolat", position.coords.latitude);
        localStorage.setItem("geolng", position.coords.longitude);
        localStorage.setItem("lat", position.coords.latitude);
        localStorage.setItem("lng", position.coords.longitude);

        
      });
    
      setTimeout(() => {
        this.setState({ mapCenter: { lat: localStorage.getItem("geolat"), lng: localStorage.getItem("geolng") } });
        Geocode.fromLatLng(localStorage.getItem("geolat"), localStorage.getItem("geolng")).then(
            (response) => {
                const address = response.results[0].formatted_address;
               //console.log(address);
                this.setState({address:address})
                localStorage.setItem("center", address)
            }
        )
        if (this.state.address===''){
        this.setState({address:localStorage.getItem("center")})}

    }, 300);
      
    }
  }

  handleChange = (address) => {
      this.setState({ address });
  };

  handleSelect = (address) => {
      geocodeByAddress(address)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
              this.setState({ address });
              this.setState({ mapCenter: latLng });
             // console.log("Success", latLng);
              localStorage.setItem("lat", latLng.lat);
              localStorage.setItem("lng", latLng.lng);
              localStorage.setItem("center", address );
          })
          .catch((error) => console.error("Error", error));
  };

  moveMarker(props, marker, e) {
    //  console.log(e.latLng.lat(), e.latLng.lng()); // get the new coordinates after drag end
      Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
          (response) => {
              const address = response.results[0].formatted_address;
           //   console.log(address);
              this.setState({ address });
              this.setState({ mapCenter: { lat: e.latLng.lat(), lng: e.latLng.lng() } });
              localStorage.setItem("lat", e.latLng.lat());
              localStorage.setItem("lng", e.latLng.lng() );
              localStorage.setItem("center", address );
            //  console.log(localStorage);
          },
          (error) => {
              console.error(error);
          }
      );
  }

  render() {
      return (
          <div>
              <div className="trip-map-1-parent3">
                  <Map
                      google={this.props.google}
                      zoom={27}
                      initialCenter={{
                          lat: this.state.mapCenter.lat,
                          lng: this.state.mapCenter.lng,
                      }}
                      center={{ lat: this.state.mapCenter.lat, lng: this.state.mapCenter.lng }}
                  >
                      <Marker
                          position={{
                              lat: this.state.mapCenter.lat,
                              lng: this.state.mapCenter.lng,
                          }}
                          draggable={true}
                          opacity={1}
                          onDragend={this.moveMarker.bind(this)}
                      />
                  </Map>
              </div>
              <br />
              <br />
              <div className="input-wrapper7">
                  <div className="input12">
                      <div className="input-title9" ><Text>accidentlocation</Text></div>

                      <PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                  <input id='accidentlocation'
                                      {...getInputProps({
                                          placeholder: "Search",
                                          className: "input-wrapper8",
                                      })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                          const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                          // inline style for demonstration purpose
                                          const style = suggestion.active ? { backgroundColor: "#fafafa", cursor: "pointer" } : { backgroundColor: "#ffffff", cursor: "pointer" };
                                          return (
                                              <div
                                                  {...getSuggestionItemProps(suggestion, {
                                                      className,
                                                      style,
                                                  })}
                                              >
                                                  <span>{suggestion.description}</span>
                                              </div>
                                          );
                                      })}
                                  </div>
                              </div>
                          )}
                      </PlacesAutocomplete>
                  </div>
              </div>
             
          </div>
      );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAGrKp0iO2wgSwsBq3k8j3SBjQxKoqOqks",
})(MapContainer);
