import * as React from "react";
import { useCallback, useState, useEffect } from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import image10 from "../assets/image10.png";
import { Text } from "./Translate";

const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  useEffect(() => {
    const key = window.location.search;
    const token = key.replace('?token=','');
    // console.log(token)
    // const token = 'eyJhbGciOiJkaXIiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2In0..qgDac6_--nLfowsCXXKQjw.1F9nhSLPbeUWLviwgctfmXgFWmEcLKITL8O55Z10TDFLDnqivru_RwOJNcyujVVKi48CyNbDPB9jIcQa18JBp66Dh6MyKBRnasUgPE5DkuueDfg72ZVOJytcl05DoAVUq79Kol11CwaGPB00eh-u6gqdLm0rSulZJpBhAzVLMsJxlQ9h9ukFsRN5W3nd_lSwSB8H8YiDGrZcYFOx4NqphrI9_tXsBryJAoSGJ_mzKKr_1t7EiHiiV1DcorRVEX72EIaj2l3hSlWfxK5YW9Ao2Gb7LzCHRG8WuQO-w0aY9byzftAd1kIDzfydv4HsA7uNWs2WEjYWxX7thAqJ46gxXXR-6P7issVZDXIwlFkQmbI.eFdCa7eRYKPDAJqdyNwnng'
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/token`;
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ token: `${token}` }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("access", data.access.token);
        localStorage.setItem("access-timer", data.access.expiresAt);
        localStorage.setItem("refresh", data.refresh.token);
        // console.log(localStorage);
        navigate("/home");
        setError(false);
      })
      .catch((error) => {
        console.log("Invalid Token");
        setError(true);
      });
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {error && (
          <>
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                sx={{ m: 1, bgcolor: "#46bedc" }}
                src={image10}
                style={{ width: "250px" }}
              />

              <br />
              <Typography component="h1" variant="h5">
                FNOL App
              </Typography>
            </Box>
            <p className="mt-4 text-center text-danger">
              <Text>sessionexpired</Text>
            </p>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
}
