import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../css/StatementIntro1.css";
import BarTwo from "./Bar-two";
import { Text } from "./../components/Translate";



const StatementIntro1 = () => {
  const navigate = useNavigate();

  const onFrameContainer2Click = useCallback(() => {
    localStorage.setItem("emplekomena_oximata", '2');
    navigate("/statement-intro2");
  }, [navigate]);

  return (
    <div className="wrapperpage">
        <div id="wrapper">
            <BarTwo headerName={<Text>newstatement</Text>} />
            <div className="parent48">
                <b className="b54"><Text>howmanyvehicles</Text></b>

                <div class="radio-list">
                    <div className="frame-wrapper10">
                        <div className="frame-parent102">
                            <div className="frame-parent103">
                                <div className="icontheft-parent">
                                    <div class="radio-item">
                                        <input type="radio" name="radio" id="radio1" disabled />
                                        <label for="radio1">1</label>
                                    </div>
                                </div>
                                <div>
                                    <div className="icontheft-parent">
                                        <div class="radio-item">
                                            <input type="radio" name="radio" id="radio2" checked />
                                            <label for="radio2">2</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-parent103">
                                <div className="icontheft-parent">
                                    <div class="radio-item">
                                        <input type="radio" name="radio" id="radio3" disabled />
                                        <label for="radio3">3</label>
                                    </div>
                                </div>

                                <div className="icontheft-parent">
                                    <div class="radio-item">
                                        <input type="radio" name="radio" id="radio4" disabled />
                                        <label for="radio4">4</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="button-wrapper38">
            <div className="button73" onClick={onFrameContainer2Click}>
                <div className="continue53"><Text>next</Text></div>
            </div>
        </div>
    </div>
);

};

export default StatementIntro1;
