import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/NdDriver9.css";
import Bar from "./Bar";
import BarTwo from "./Bar-two";
import { Text } from "./../components/Translate";

const initialState = {
    Arkikl: '',
    montelo: '',
    marka: '',
    xroma: '',
  }

const NdDriver9 = () => {
  const navigate = useNavigate();
  const [{ Arkikl, montelo, marka, xroma }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  useEffect(() => {
    // Initialization code comes here
    setState({Arkikl:localStorage.getItem("Ar_kikloforias_B_oximatos"),montelo:localStorage.getItem("montelo"),marka:localStorage.getItem("marka"),xroma:localStorage.getItem("xroma")})
}, []);
  
  const handleSubmit = (e) => {
    e.preventDefault()
    localStorage.setItem("Ar_kikloforias_B_oximatos", Arkikl );
    localStorage.setItem("montelo", montelo );
    localStorage.setItem("marka", marka );
    localStorage.setItem("xroma", xroma );
    console.log(Arkikl, montelo, marka, xroma)
    onButtonContainerClick()
  }

  const onButtonContainerClick = useCallback(() => {
    navigate("/2nd-driver9");
  }, [navigate]);


  return (
        <div className="nd-driver9">
            <div className="wrapperpage">
                <div id="wrapper">
                    <BarTwo headerName={<Text>vehiclebdetails</Text>} />
                    <div className="progress-bar-parent34">
                        <div className="progress-bar39">
                            <div className="progress-bar-child165" />
                            <div className="progress-bar-child165" />
                            <div className="progress-bar-child167" />
                            <div className="progress-bar-child167" />
                        </div>
                        <form
                name='nextpage' validate onSubmit={handleSubmit}
              >
                            <div className="parent79">
                                <div className="div355"><Text>fillinvehiclebdetails</Text></div>
                                <div className="frame-parent160">
                                    <div className="frame-parent161">
                                        <div className="input-wrapper179">
                                            <div className="input376">
                                                <div className="input-title203"><Text>licenseplate</Text></div>
                                                <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='Arkikl'
                                                value={Arkikl}
                                                name='Arkikl'
                                                required       
                                                onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="input376">
                                            <div className="input-title203"><Text>brand</Text></div>
                                            <input  type="text"
                                                className="input-wrapper180"
                                                id='marka'
                                                value={marka}                
                                                name='marka'
                                                required
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="frame-parent161">
                                        <div className="input376">
                                            <div className="input-title203"><Text>model</Text></div>
                                            <input  type="text"
                                                className="input-wrapper180"
                                                id='montelo'
                                                value={montelo}                
                                                name='montelo'
                                                required
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input376">
                                            <div className="input-title203"><Text>colour</Text></div>
                                            <input  type="text"
                                                className="input-wrapper180"
                                                id='xroma'
                                                value={xroma}                
                                                name='xroma'
                                                required
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button-wrapper38">
                       
                            <button type='submit' className="button73" style={{width:'288px',color:'white'}} ><Text>next</Text></button>
                    </div>
                            </form>
                        </div>
                  
                  
                </div>
            </div>
        </div>

  );
};

export default NdDriver9;
