import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./css/global.css";

import { TranslateProvider, availableLocales } from "./components/Translate";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <TranslateProvider languages={availableLocales}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </TranslateProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
