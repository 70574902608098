import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/NdDriver7.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import BarTwo from "./Bar-two";
import { Text } from "./../components/Translate"; 
import chevron from '../assets/insideicon.svg'
import ModalInfo from "../components/ModalVehicleB";


const initialState = {
    eponimo: '',
    onoma: '',
    tilefono: '',
    email: '',
    birthday: '',
    diefthinsi: '',
    tk: '',
    xora:' ',
    onoma_asfal: localStorage.getItem('asfalistiki_B_odigou'),
    ar_simvolaiou: '',
    ar_prasinis: '',
    ar_diplomatos: '',
    katigoria: '',
    isxis_ews: '',
  }

const NdDriver7 = () => {
    const [ModalComplete, setModalComplete] = useState(false);

    const CompleteModal = (e) => {
      e.preventDefault()
      handleSubmit(e)
      setModalComplete(true)
      
    }
  const navigate = useNavigate();
  const [{ eponimo, onoma, tilefono, email, xora, birthday, diefthinsi,tk,onoma_asfal,ar_simvolaiou,ar_diplomatos,ar_prasinis, katigoria,isxis_ews}, setState] = useState(initialState)
  const onInputContainer4Click = useCallback(() => {
    
    navigate("/2nd-driver5");
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()
    localStorage.setItem("Onoma_B_odigou", onoma );
    localStorage.setItem("eponimo_B_odigou", eponimo );
    localStorage.setItem("tilefono_B_odigou", tilefono);
    {email!==null?localStorage.setItem("email_B_odigou", email ):<a/>}
    {xora!==null?localStorage.setItem("xora_B_odigou", xora):<a/>}
    {tk!==null?localStorage.setItem("tk_B_odigou", tk):<a/>}
    {birthday!==null?localStorage.setItem("im_gennisis_B_odigou", birthday):<a/>}
    {diefthinsi!==null?localStorage.setItem("diefthinsi_B_odigou", diefthinsi):<a/>}
    {onoma_asfal!==null?localStorage.setItem("asfalistiki_B_odigou", onoma_asfal):<a/>}
    {ar_simvolaiou!==null?localStorage.setItem("ar_simvolaiou_B_odigou", ar_simvolaiou):<a/>}
    {ar_diplomatos!==null?localStorage.setItem("ar_diplomatos_B_odigou", ar_diplomatos ):<a/>}
    {ar_prasinis!==null?localStorage.setItem("ar_prasinis_B_odigou", ar_prasinis):<a/>}
    {isxis_ews!==null?localStorage.setItem("isxus_diplomatos_B_odigou", isxis_ews ):<a/>}
    {katigoria!==null?localStorage.setItem("katigoria_diplomatos_B_odigou", katigoria):<a/>}
    onInputContainer4Click()

  }
  useEffect(() => {
    console.log(localStorage.getItem("asfalistiki_B_odigou"))

     setState({ eponimo:localStorage.getItem("eponimo_B_odigou"), 
     onoma:localStorage.getItem("Onoma_B_odigou"), tilefono: localStorage.getItem("tilefono_B_odigou"), 
     email: localStorage.getItem("email_B_odigou"), xora:localStorage.getItem("xora_B_odigou"), 
     birthday:localStorage.getItem("im_gennisis_B_odigou"), diefthinsi:localStorage.getItem("diefthinsi_B_odigou"),
     tk:localStorage.getItem("tk_B_odigou"),onoma_asfal: localStorage.getItem("asfalistiki_B_odigou"),
     ar_simvolaiou:localStorage.getItem("ar_simvolaiou_B_odigou"),ar_diplomatos:localStorage.getItem("ar_diplomatos_B_odigou"),
     ar_prasinis:localStorage.getItem("ar_prasinis_B_odigou"), katigoria:localStorage.getItem("katigoria_diplomatos_B_odigou"),
     isxis_ews:localStorage.getItem("isxus_diplomatos_B_odigou")})

    
   }, []);


  return (
    <div className="nd-driver7">
        <div className="wrapperpage">
            <div id="wrapper">
                <BarTwo headerName={<Text>vehiclebdetails</Text>} />
                <div className="progress-bar-parent34">
                    <div className="progress-bar37">
                        <div className="progress-bar-child157" />
                        <div className="progress-bar-child157" />
                        <div className="progress-bar-child157" />
                        <div className="progress-bar-child160" />
                    </div>
                    <div className="div338">
                        <div className="div339">
                            <div className="div340"><Text>driverdetails</Text></div>
                            <div className="div341"><Text>fieldswithasterisk</Text></div>
                        </div>
                    </div>
                    <form
                name='nextpage' validate onSubmit={handleSubmit}
              >
                    <Accordion id='accordion' className="infopersonalexpand13">
                        <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>
                                <div className="icon-parent20">
                                    <img className="icon38" alt="" src="/icon311.svg" />
                                    <div className="div343"><Text>personaldetails</Text>*</div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="frame-parent156">
                                    <div className="input-parent47">
                                        <div className="input342">
                                            <div className="input-title185"><Text>surname</Text>*</div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='eponimo'
                                                value={eponimo}
                                                name='eponimo'
                                                required       
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title185"><Text>name</Text>*</div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='onoma'
                                                value={onoma}
                                                name='onoma'
                                                required       
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="input-parent47">
                                        <div className="input342">
                                            <div className="input-title185"><Text>phonenumber</Text>*</div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='tilefono'
                                                value={tilefono}
                                                name='tilefono'
                                                required       
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title185"><Text>email</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='email'
                                                value={email}
                                                name='email'
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title185"><Text>dateofbirth</Text></div>
                                            <input
                                                type="date"
                                                className="input-wrapper180"
                                                id='birthday'
                                                value={birthday}
                                                name='birthday'      
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="input-parent47">
                                        <div className="input342">
                                            <div className="input-title190"><Text>address</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='diefthinsi'
                                                value={diefthinsi}
                                                name='diefthinsi'      
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title190"><Text>postalcode</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='tk'
                                                value={tk}
                                                name='tk'      
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title190"><Text>country</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='xora'
                                                value={xora}
                                                name='xora'      
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion id='accordion' className="infopersonalexpand13">
                        <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>
                                <div className="icon-parent20">
                                    <img className="icon38" alt="" src="/insurance3.svg" />
                                    <div className="div343"><Text>insurancedetails</Text></div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="frame-parent156">
                                    <div className="input-parent47">
                                        <div className="input342">
                                            <div className="input-title190"><Text>nameofinsurancecompany</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='onoma_asfal'
                                                value={onoma_asfal}
                                                name='onoma_asfal'      
                                                />
                                        </div>
                                        <div className="input342">
                                            <div className="input-title190"><Text>contractno</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='ar_simvolaiou'
                                                value={ar_simvolaiou}
                                                name='ar_simvolaiou'      
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title185"><Text>greencardnoif</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='ar_prasinis'
                                                value={ar_prasinis}
                                                name='ar_prasinis'      
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion id='accordion' className="infopersonalexpand13">
                        <AccordionSummary expandIcon={<img src={chevron} style={{width:'20px'}}/>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>
                                <div className="icon-parent20">
                                    <img className="icon38" alt="" src="/icon7.svg" />
                                    <div className="div343"><Text>driverlicensedetails</Text></div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="frame-parent156">
                                    <div className="input-parent47">
                                        <div className="input342">
                                            <div className="input-title190"><Text>driverlicenseno</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='ar_diplomatos'
                                                value={ar_diplomatos}
                                                name='ar_diplomatos'      
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title190"><Text>category</Text></div>
                                            <input
                                                type="text"
                                                className="input-wrapper180"
                                                id='katigoria'
                                                value={katigoria}
                                                name='katigoria'      
                                                onChange={handleChange}/>
                                        </div>
                                        <div className="input342">
                                            <div className="input-title185"><Text>validthrough</Text></div>
                                            <input
                                                type="date"
                                                className="input-wrapper180"
                                                id='isxis_ews'
                                                value={isxis_ews}
                                                name='isxis_ews'      
                                                onChange={handleChange}/>
                                        </div>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                  
                    <div className="button-wrapper38">
                    <button type='submit' className="button73" style={{width:'288px',color:'white'}}><Text>next</Text></button>
                    </div>
                    </form>
                </div>

            </div>
        </div>
    </div>

  );
};

export default NdDriver7;
