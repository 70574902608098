import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/NdDriver10.css";
import BarTwo from "./Bar-two";
import Bar from "./Bar";
import { Text } from "./../components/Translate";

const NdDriver10 = () => {
  const [oxima, setOxima] = useState('');
  const navigate = useNavigate();

  const onFrameContainer1Click = useCallback(() => {
    navigate("/2nd-driver11");
  }, [navigate]);

  function NextPage(e) {
   
        localStorage.setItem("oxima_B_odigou", 'Αυτοκίνητο' );
        onFrameContainer1Click()
      
    
  }



  return (
        <div className="nd-driver10">
            <div className="wrapperpage">
                <div id="wrapper">
                    <BarTwo headerName={<Text>vehiclebdetails</Text>} />
                    <div className="progress-bar-parent34">
                        <div className="progress-bar40">
                            <div className="progress-bar-child169" />
                            <div className="progress-bar-child170" />
                            <div className="progress-bar-child170" />
                            <div className="progress-bar-child170" />
                        </div>
                        <div className="div356"><Text>vehiclecategory</Text></div>
                        <div className="frame-parent162">
                            <div className="frame-parent163">
                                <div class="radio-list">
                                    <div className="frame-wrapper10">
                                        <div className="frame-parent102">
                                            <div className="frame-parent103">
                                                <div className="icontheft-parent"  >
                                                    <div class="radio-item">
                                                        <input type="radio" name="radio" id="radio1" checked />
                                                        <label for="radio1">
                                                        <span className="label4"><Text>car</Text></span>
                                                            <img className="icontheft" alt="" src="/iconcar-empty2.svg" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="icontheft-parent">
                                                        <div class="radio-item">
                                                            <input type="radio" name="radio" id="radio2" disabled  />
                                                            <label for="radio2">
                                                            <span className="label4"><Text>motorcycle</Text></span>
                                                                <img className="icontheft" alt="" src="/iconcar-empty11.svg" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="frame-parent103">
                                                <div className="icontheft-parent"  >
                                                    <div class="radio-item">
                                                        <input type="radio" name="radio" id="radio3" disabled/>
                                                        <label for="radio3">
                                                        <span className="label4"><Text>truck</Text></span>
                                                            <img className="icontheft" alt="" src="/iconcar-empty21.svg" />
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="icontheft-parent">
                                                    <div class="radio-item">
                                                        <input type="radio" name="radio" id="radio4"  disabled/>
                                                        <label for="radio4">
                                                        <span className="label4"><Text>bicycle</Text></span>
                                                            <img className="icontheft" alt="" src="/iconcar-empty3.svg" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="icontheft-parent">
                                                <div class="radio-item">
                                                    <input type="radio" name="radio" id="radio5" disabled/>
                                                    <label for="radio5">
                                                    <span className="label4"><Text>other</Text></span>
                                                        <img className="icontheft" alt="" src="/iconcar-empty4.svg" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="button-wrapper38">
                        <div className="button73" onClick={NextPage}>
                            <div className="continue53"><Text>next</Text></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default NdDriver10;
