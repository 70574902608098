import { useCallback,useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/StatementIntro2.css";
import Modal from 'react-bootstrap/Modal';

const Insurance = (props) => {
  const [Asfalia, setAsfalia] = useState('');

 useEffect(() => {

  console.log(localStorage.getItem("asfalistiki_B_odigou"))
  setAsfalia(localStorage.getItem("asfalistiki_B_odigou"))
 console.log(Asfalia)
 
})
  return ( 
   
    <div className="icontheft-parent" onClick={() => {props.handleAsfal(props.name); setAsfalia(props.name)}}  >
          <div class="radio-item2" id='fil-dil'>   
                <input type="radio" name="radio" id={props.radio} value={props.name} checked={props.name===Asfalia} />
                <label style={{textAlign:'left'}}for={props.radio}>{props.name}
                </label>
          </div>
    </div>
    
   

    
  );
};

export default Insurance;
